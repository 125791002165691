import { useAsyncOperation } from '@proscom/prostore-react/lib/es/useAsyncOperation';
import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { IToken } from '../interfaces/IFile';

export interface CreateTokenQueryVars {
  fileId: string;
}

export interface CreateCatalogTokenQueryVars {
  catalogId: number;
}

export function useFile(variables: CreateTokenQueryVars) {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (): Promise<IToken> => {
      return client
        .post(`/api/createFileOneTimeToken/?fileId=${variables.fileId}`, {})
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error(err);
          throw new Error('Возникла ошибка при загрузке файла. Попробуйте еще раз');
        });
    },
    {
      singleton: true
    }
  );
}

export function useCatalogFile(variables: CreateCatalogTokenQueryVars) {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (): Promise<IToken> => {
      return client
        .post(`/api/createCatalogFileOneTimeToken/?catalogId=${variables.catalogId}`, {})
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error(err);
          throw new Error('Возникла ошибка при загрузке файла. Попробуйте еще раз');
        });
    },
    {
      singleton: true
    }
  );
}
