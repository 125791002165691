import React from 'react';
import { Box } from '../Box/Box';
import s from './Header.module.scss';

export interface HeaderProps extends React.HTMLProps<HTMLDivElement> {
  header: string;
}

export function Header({ header }: HeaderProps) {
  return (
    <header className={s.Header}>
      <Box>
        <h1 className={s.Header__pageHeader}>{header}</h1>
      </Box>
    </header>
  );
}
