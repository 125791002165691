import React from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { TextInput, TextInputProps } from './TextInput';
import s from './Search.module.scss';

export function Search(props: TextInputProps) {
  return (
    <TextInput
      type="search"
      className={s.Search}
      classes={{ input: s.Search__input }}
      leftIcon={<SearchIcon />}
      isClearable
      {...props}
    />
  );
}
