import { SelectOption } from '../components/ui/Input/Select';

export function useResponseSelectData<T extends { name: string; id: number }>(data: T[] | null): SelectOption[] {
  return (
    data?.map((dataItem: T) => ({
      label: dataItem.name,
      value: dataItem.id
    })) || []
  );
}
