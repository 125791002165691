import React from 'react';
import clsx from 'clsx';
import { getBudgetYears } from '../../../../utils/catalog';
import { IBudget } from '../../../../store/interfaces/IBudget';
import { getBudgetInfo } from '../../../../utils/budget';
import s from './BudgetCompactTable.module.scss';

export interface BudgetCompactTableProps {
  data: IBudget[];
  collapsed?: boolean;
}

export function BudgetCompactTable({ data, collapsed }: BudgetCompactTableProps) {
  const sortedYears = getBudgetYears(data);

  return (
    <div
      className={clsx(s.BudgetCompactTable, {
        [s.BudgetCompactTable_collapsed]: collapsed
      })}
    >
      {sortedYears.map((budgetItem, budgetItemIndex) => {
        const [budgetLabel, budgetValue] = getBudgetInfo(budgetItem);
        return (
          <div key={budgetItemIndex} className={s.BudgetCompactTableItem}>
            <div className={s.BudgetCompactTableItem__label}>{budgetLabel}</div>
            <div className={s.BudgetCompactTableItem__value}>{budgetValue} млн</div>
          </div>
        );
      })}
    </div>
  );
}
