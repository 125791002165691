import { useStore } from '@proscom/prostore-react';
import { useEffect, useState } from 'react';
import { ShowBudgetStore } from '../../store/ShowBudgetStore';
import { STORE_AUTH, STORE_SHOW_BUDGET } from '../../store/stores';
import { AuthStore } from '../../store/AuthStore';

export function useBudgetAccess() {
  const [showBudgetState, showBudgetStore] = useStore<ShowBudgetStore>(STORE_SHOW_BUDGET);
  const [authState, authStore] = useStore<AuthStore>(STORE_AUTH);

  const [isBudgetVisible, setIsBudgetVisible] = useState(false);

  useEffect(() => {
    if (!authState.authData?.user.accessToFinance) {
      setIsBudgetVisible(false);
    } else {
      setIsBudgetVisible(showBudgetState.value || false);
    }
  }, [authState, showBudgetState]);

  return isBudgetVisible;
}
