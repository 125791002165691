import React from 'react';
import NothingFoundImage from '../../../../assets/images/nothing-found.svg';
import s from './NothingFound.module.scss';

export interface NothingFoundProps {
  label: string;
}

export function NothingFound({ label }: NothingFoundProps) {
  return (
    <div className={s.NothingFound}>
      <img src={NothingFoundImage} alt="Ничего не найдено" className={s.NothingFound__image} />
      <div className={s.NothingFound__title}>{label}</div>
    </div>
  );
}
