import React, { useRef, useState } from 'react';
import { SidebarModal, SidebarModalProps } from '../../../common/components/ui/Modal/SidebarModal';
import AddUserImage from '../../../assets/images/add-user.svg';
import { ModalHeader } from '../../../common/components/ui/Modal/ModalHeader';
import { useUserCreate } from '../../../store/mutation/useUserCreate';
import { FormErrorMessage } from '../../../common/components/ui/Form/FormErrorMessage';
import { TBackendUserFormData, UserDataForm } from './UserDataForm';
import s from './UserModal.module.scss';

export interface AddUserModalProps extends SidebarModalProps {
  onSubmit?: () => void;
}

export function AddUserModal({ onClose, onSubmit }: AddUserModalProps) {
  const [errorText, setErrorText] = useState(null);
  const formSubmitRef = useRef<HTMLInputElement>(null);

  const addUserRequest = useUserCreate();

  function handleSubmitForm(formData: TBackendUserFormData) {
    addUserRequest
      .run({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: formData.role,
        accessToFinance: Boolean(formData.finances_access),
        accessibleCatalogIds: formData.catalogs,
        accessibleBlockIds: formData.deputies,
        accessibleDirectionIds: formData.directions,
        accessibleProjectIds: formData.projects
      })
      .then(() => {
        onClose?.();
        onSubmit?.();
      })
      .catch((err) => {
        setErrorText(err.message);
      });
  }

  return (
    <SidebarModal onClose={onClose}>
      <ModalHeader
        header="Добавление пользователя"
        beforeHeader={<img src={AddUserImage} alt="Добавить пользователя" className={s.UserModal__picture} />}
      />

      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}

      <UserDataForm formSubmitRef={formSubmitRef} onSubmit={handleSubmitForm} />
    </SidebarModal>
  );
}
