import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import s from './TextInput.module.scss';

export interface TextInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
  isClearable?: boolean;
  leftIcon?: ReactNode;
  isFormControlled?: boolean;
  errorText?: string;
  classes?: {
    input?: string;
    leftIcon?: string;
  };
}

export function TextInput({
  name,
  value,
  onChange,
  isClearable = true,
  leftIcon,
  classes,
  className,
  isFormControlled = true,
  errorText,
  ...props
}: TextInputProps) {
  return (
    <div
      className={clsx(s.TextInput, className, {
        [s.TextInput_invalid]: errorText
      })}
    >
      <input
        value={value}
        className={clsx(s.TextInput__input, classes?.input)}
        onChange={(e) => onChange?.(e.target.value)}
        name={name}
        {...props}
      />
      {leftIcon && <div className={clsx(s.TextInput__icon, s.TextInput__leftIcon, classes?.leftIcon)}>{leftIcon}</div>}
      {isClearable && value && (
        <button
          className={clsx(s.TextInput__icon, s.TextInput__resetButton)}
          onClick={() => onChange?.('')}
          tabIndex={-1}
        >
          <CloseIcon />
        </button>
      )}
      {errorText && <div className={s.TextInput__error}>{errorText}</div>}
    </div>
  );
}
