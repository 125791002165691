import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Box } from '../../common/components/ui/Box/Box';
import { Slideshow } from '../../common/components/ui/Slideshow/Slideshow';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { useQuery } from '../../common/hooks/useQuery';
import { useProject } from '../../store/query/useProject';
import { SLIDES } from './ProjectPage';
import s from './PresentationPage.module.scss';

export interface PresentationPageProps {
  match: { params: { projectId: string; catalogId: string } };
}

export function PresentationPage({ match }: PresentationPageProps) {
  const [query, changeQuery] = useQuery();

  const catalogId = +match.params.catalogId;
  const projectId = +match.params.projectId;
  const projectQuery = useProject(projectId);
  const projectData = projectQuery.state.data;

  const querySlideIndex = +query?.slide || 0;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(querySlideIndex);

  useEffect(() => {
    if (querySlideIndex < 0 || querySlideIndex > SLIDES.length - 1) {
      changeQuery({
        slide: 0
      });
      return;
    }

    setCurrentSlideIndex(querySlideIndex);
  }, [changeQuery, querySlideIndex]);

  return (
    <div className={s.PresentationPage}>
      <Box className={s.PresentationPage__viewport}>
        <div className={s.PresentationPage__slides}>
          {projectData?.imageTokens.map((slide, slideIndex) => (
            <button
              onClick={() => {
                changeQuery({
                  slide: slideIndex
                });
              }}
              className={clsx(s.PresentationPage__slide, {
                [s.PresentationPage__slide_active]: slideIndex === currentSlideIndex
              })}
            >
              <img src={'/api/getFile?token=' + slide} alt="" key={slideIndex} />
            </button>
          ))}
        </div>
        <div className={s.PresentationPage__currentSlide}>
          <Link to={`/catalog/${catalogId}/project/${projectId}`} className={s.PresentationPage__close}>
            <CloseIcon />
          </Link>

          <Slideshow
            slides={projectData?.imageTokens || []}
            currentSlideIndex={+currentSlideIndex}
            isFullSizeButton={false}
            onSlideChange={(slideIndex) => {
              changeQuery({
                slide: slideIndex
              });
            }}
          />
        </div>
      </Box>
    </div>
  );
}
