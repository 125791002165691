import { WebStorageValueStore } from '@proscom/prostore-local-storage';
import { LOCAL_STORAGE_KEY_SHOW_BUDGET } from './storageKeys';

export class ShowBudgetStore extends WebStorageValueStore<boolean> {
  constructor() {
    super(localStorage, LOCAL_STORAGE_KEY_SHOW_BUDGET, (value) => {
      return value;
    });
  }

  toggleView() {
    this.setValue(!this.getValue());
  }

  getValue(): boolean {
    return this.state.value || false;
  }
}
