import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import s from './Tag.module.scss';

export interface TagProps extends React.HTMLProps<HTMLDivElement> {
  color?: string;
  autoColored?: boolean;
}

export function Tag({ children, autoColored, color, ...props }: TagProps) {
  return (
    <div
      className={clsx(s.Tag, {
        [s.Tag_autoColored]: autoColored,
        [s.Tag_colored]: color
      })}
      style={color ? ({ backgroundColor: color } as CSSProperties) : undefined}
    >
      {children}
    </div>
  );
}
