import React, { useMemo } from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevronRightIcon } from '../../../../assets/icons/chevron-right.svg';
import { ReactComponent as FullIcon } from '../../../../assets/icons/full.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import NothingFoundImage from '../../../../assets/images/nothing-found.svg';
import { useFileLink } from '../../../hooks/useFileLink';
import s from './Slideshow.module.scss';

export interface SlideshowProps {
  slides: string[];
  currentSlideIndex: number;
  isFullSizeButton?: boolean;
  onSlideChange?: (slideIndex: number) => void;
  onFullClick?: (slideIndex?: number) => void;
  fileId?: string;
}

export function Slideshow({
  slides,
  currentSlideIndex,
  isFullSizeButton = true,
  fileId,
  onSlideChange,
  onFullClick
}: SlideshowProps) {
  const slideUrls = useMemo(() => {
    return slides.map((url) => '/api/getFile?token=' + url);
  }, [slides]);

  const hasSlides = slideUrls.length > 0;

  const handleLinkClick = useFileLink(fileId);

  return (
    <div className={s.Slideshow}>
      {!hasSlides && (
        <div className={s.Slideshow__empty}>
          <img src={NothingFoundImage} alt="Ничего не найдено" />
        </div>
      )}
      {hasSlides && (
        <>
          <img
            className={s.Slideshow__slide}
            src={slideUrls[currentSlideIndex] || ''}
            alt={'Слайд ' + currentSlideIndex}
          />
          <button
            className={clsx(s.Slideshow__action, s.Slideshow__prev)}
            disabled={currentSlideIndex === 0}
            onClick={currentSlideIndex ? () => onSlideChange?.(currentSlideIndex - 1) : undefined}
          >
            <ChevronRightIcon style={{ transform: 'scaleX(-1)' }} />
          </button>
          <button
            className={clsx(s.Slideshow__action, s.Slideshow__next)}
            disabled={currentSlideIndex === slides.length - 1}
            onClick={currentSlideIndex < slides.length - 1 ? () => onSlideChange?.(currentSlideIndex + 1) : undefined}
          >
            <ChevronRightIcon />
          </button>
          {isFullSizeButton && (
            <button
              className={clsx(s.Slideshow__action, s.Slideshow__full)}
              onClick={() => onFullClick?.(currentSlideIndex)}
            >
              <FullIcon />
            </button>
          )}
          {fileId && (
            <button onClick={() => handleLinkClick?.()} className={clsx(s.Slideshow__action, s.Slideshow__download)}>
              <DownloadIcon />
            </button>
          )}
        </>
      )}
    </div>
  );
}
