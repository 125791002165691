import clsx from 'clsx';
import React, { useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import { Loader } from '../../../common/components/ui/Loader/Loader';
import { SubmitButton } from '../../../common/components/ui/SubmitButton/SubmitButton';
import { AuthStore } from '../../../store/AuthStore';
import { STORE_AUTH } from '../../../store/stores';
import s from './SignInForm.module.scss';

export interface ISignInFormProps {
  className?: string;
}

export function SignInForm({ className }: ISignInFormProps) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authStoreState, authStore] = useStore<AuthStore>(STORE_AUTH);
  const error = authStoreState.error;

  const handleFormSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (!authStoreState.loading) authStore.logIn(username, password);
  };

  return (
    <form className={clsx(s.Form, className)} onSubmit={handleFormSubmit}>
      <h1 className={s.Form__heading}>Авторизация</h1>
      <p className={clsx(s.Form__message, { [s.Form__message_error]: !!error })}>
        {error || 'Введите данные, которые вы получили от модератора.'}
      </p>

      <label htmlFor={'username'} className={s.Form__label}>
        Почта
      </label>
      <input
        id={'username'}
        name={'username'}
        className={s.Form__input}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={authStoreState.loading}
      />
      <label htmlFor={'password'} className={s.Form__label}>
        Пароль
      </label>
      <input
        id={'password'}
        name={'password'}
        className={s.Form__input}
        type={'password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={authStoreState.loading}
      />

      <div className={s.Form__submitContainer}>
        {authStoreState.loading && <Loader inline />}

        <SubmitButton className={s.Form__submit} disabled={authStoreState.loading}>
          Войти
        </SubmitButton>
      </div>
    </form>
  );
}
