import React, { useEffect, useMemo } from 'react';
import { useQuery } from '../../common/hooks/useQuery';
import { Header } from '../../common/components/ui/Header/Header';
import { useUsers } from '../../store/query/useUsers';
import { IUser } from '../../store/interfaces/IUser';
import { queryLoader } from '../../common/components/utils/queryLoader';
import { UserTable } from './table/UsersTable';
import { UsersFilter } from './filter/UsersFilter';
import { UserModal } from './modal/UserModal';
import { AddUserModal } from './modal/AddUserModal';

export type TSort = 'asc' | 'desc';

export default function UsersPage() {
  const [query, changeQuery] = useQuery();
  const querySearch = query?.q;
  const queryUser = query?.user;
  const queryAction = query?.action;
  const querySort = query?.sort;

  const usersQuery = useUsers();
  const usersData = usersQuery.state.data;

  const filteredUsersData = useMemo(() => {
    if (!usersData) return [];

    let usersList = usersData;
    if (querySearch) {
      usersList = usersList.filter((user) => user.name.toLowerCase().indexOf(querySearch.toLowerCase()) > -1);
    }

    return usersList;
  }, [usersData, querySearch]);

  useEffect(() => {
    if (querySort && querySort !== 'asc' && querySort !== 'desc') {
      changeQuery({
        sort: undefined
      });
    }
  }, [querySort, changeQuery]);

  const currentUser: IUser | null = useMemo(() => {
    if (!queryUser) {
      return null;
    }

    return usersData?.find((user) => user.id === +queryUser) || null;
  }, [queryUser, usersData]);

  function handleSubmitModalForm() {
    usersQuery.load();
  }

  return (
    <>
      <Header header="Список пользователей" />
      <UsersFilter />
      {queryLoader(usersQuery) || <UserTable data={filteredUsersData} />}

      {currentUser && (
        <UserModal
          data={currentUser}
          editableMode={queryAction === 'user-edit'}
          onClose={() => changeQuery({ user: undefined })}
          onDelete={handleSubmitModalForm}
          onSubmit={handleSubmitModalForm}
        />
      )}

      {queryAction === 'user-add' && (
        <AddUserModal onClose={() => changeQuery({ action: undefined })} onSubmit={handleSubmitModalForm} />
      )}
    </>
  );
}
