import React from 'react';
import { useStoreState } from '@proscom/prostore-react';
import { ErrorBoundary } from '../../common/components/utils/ErrorBoundary';
import { ErrorMessage } from '../../common/components/ui/ErrorMessage/ErrorMessage';
import { FeedbackStoreState } from '../../store/FeedbackStore';
import { STORE_FEEDBACK } from '../../store/stores';
import { FeedbackForm } from './FeedbackForm/FeedbackForm';

export function withDefaultLayout<Props>(Comp: React.ComponentType<Props>) {
  return function (props: Props) {
    const feedbackState = useStoreState<FeedbackStoreState>(STORE_FEEDBACK);
    return (
      <div id="wrapper-offset">
        <ErrorBoundary component={ErrorMessage}>
          {/*// @ts-ignore*/}
          <Comp {...props} />
          {feedbackState.feedbackForm && <FeedbackForm />}
        </ErrorBoundary>
      </div>
    );
  };
}
