import React from 'react';
import { NavLink } from 'react-router-dom';
import { Search } from '../../../common/components/ui/Input/Search';
import { Box } from '../../../common/components/ui/Box/Box';
import { useQuery } from '../../../common/hooks/useQuery';
import s from './CatalogFilter.module.scss';

export interface CatalogFilterProps {
  catalogId: number;
}

export function CatalogFilter({ catalogId }: CatalogFilterProps) {
  const [query, changeQuery] = useQuery();
  const searchQuery = query?.q || '';

  return (
    <Box className={s.CatalogFilter}>
      <div className={s.CatalogFilter__search}>
        <Search value={searchQuery} onChange={(value) => changeQuery({ q: value })} placeholder="Поиск по каталогу" />
      </div>
      {searchQuery === '' && (
        <div className={s.CatalogFilter__tabs}>
          <div className={s.CatalogFilter__tab}>
            <NavLink
              exact
              to={`/catalog/${catalogId}`}
              className={s.CatalogFilter__tabLink}
              activeClassName={s.CatalogFilter__tabLink_active}
            >
              Заместители мэра
            </NavLink>
          </div>
          <div className={s.CatalogFilter__tab}>
            <NavLink
              to={`/catalog/${catalogId}/direction`}
              className={s.CatalogFilter__tabLink}
              activeClassName={s.CatalogFilter__tabLink_active}
            >
              Направления
            </NavLink>
          </div>
          <div className={s.CatalogFilter__tab}>
            <NavLink
              to={`/catalog/${catalogId}/project`}
              className={s.CatalogFilter__tabLink}
              activeClassName={s.CatalogFilter__tabLink_active}
            >
              Проекты
            </NavLink>
          </div>
        </div>
      )}
    </Box>
  );
}
