import React from 'react';
import { ErrorMessage, ErrorMessageProps } from '../ErrorMessage/ErrorMessage';

export interface QueryErrorMessageProps extends ErrorMessageProps {
  error?: any;
  onReload?: () => void;
}

export function QueryErrorMessage({
  text = 'Ошибка запроса',
  error,
  onReload,
  children,
  ...props
}: QueryErrorMessageProps) {
  // В проекте не забыть: привести error в строку
  // Пример реализации:
  const errorMessage = typeof error === 'string' ? error : JSON.stringify(error);

  return (
    <ErrorMessage text={text} description={errorMessage} {...props}>
      {onReload && (
        <button type="button" onClick={onReload}>
          Reload
        </button>
      )}
      {children}
    </ErrorMessage>
  );
}
