import React from 'react';
import s from './TagsList.module.scss';

export interface TagsListProps {
  tags: string[];
}

export function TagsList({ tags }: TagsListProps) {
  return (
    <div className={s.TagsList}>
      {tags.length === 0 && 'Не указано'}
      {tags.map((tag, tagIndex) => (
        <span className={s.TagsList__tag} key={tagIndex}>
          {tag}
        </span>
      ))}
    </div>
  );
}
