import React, { ReactNode, useEffect, useRef } from 'react';
import { useClickOutside } from '@proscom/ui-react';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { usePreventBodyScroll } from '../../../hooks/usePreventBodyScroll';
import s from './SidebarModal.module.scss';

export interface SidebarModalProps extends React.HTMLProps<HTMLDivElement> {
  onClose: () => void;
  width?: number;
  scrollTop?: number;
}

export function SidebarModal({
  children,
  onClose,
  width = 490,
  className,
  scrollTop,
  ...props
}: SidebarModalProps & { children: ReactNode }) {
  const modalContentRef = useRef(null);
  const isMouseDownOnContent = useRef(false);

  useClickOutside(modalContentRef, (_) => {
    if (!isMouseDownOnContent.current) {
      onClose?.();
    }

    isMouseDownOnContent.current = false;
  });

  function handleContentMouseDown() {
    isMouseDownOnContent.current = true;
  }

  function handleModalMouseUp() {
    isMouseDownOnContent.current = false;
  }

  usePreventBodyScroll();

  useEffect(() => {
    if (scrollTop && modalContentRef.current) {
      // @ts-ignore
      // чет у меня здесь TS лагает (или вебшторм) и пишет, что объект может быть null, хотя условие делает это невозможным
      modalContentRef.current.scrollTop = scrollTop;
    }
  }, [scrollTop, modalContentRef]);

  return (
    <div className={s.SidebarModal}>
      <div className={s.SidebarModal__container}>
        <button onClick={onClose} className={s.SidebarModal__close} style={{ right: width + 16 }}>
          <CloseIcon />
        </button>
        <div
          className={clsx(s.SidebarModal__content, className)}
          style={{ width: width }}
          ref={modalContentRef}
          onMouseDown={handleContentMouseDown}
          onMouseUp={handleModalMouseUp}
          {...props}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
