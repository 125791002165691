import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useQuery } from '../../../common/hooks/useQuery';
import { TSort } from '../UsersPage';
import { Box } from '../../../common/components/ui/Box/Box';
import { NothingFound } from '../../../common/components/ui/NothingFound/NothingFound';
import { IUser } from '../../../store/interfaces/IUser';
import { UserRow } from './UserRow';
import { TableLetter } from './TableLetter';
import s from './UserTable.module.scss';

export interface UsersTableProps {
  data: IUser[];
}

export function UserTable({ data }: UsersTableProps) {
  const [query, changeQuery] = useQuery();
  const sort: TSort = query?.sort || 'asc';

  const sortedData = useMemo(() => {
    return data.sort((a, b) => (sort === 'asc' ? (a.name >= b.name ? 1 : -1) : a.name < b.name ? 1 : -1));
  }, [data, sort]);

  let currentFirstLetter: string | null = null;

  return (
    <div className={s.UserTable}>
      <div className={s.UserTable__header}>
        <Box className={s.UserTable__headerBox}>
          <div className={clsx(s.UserTable__headerCell, s.UserTable__headerCellPerson)}>ФИО</div>
          <div className={clsx(s.UserTable__headerCell, s.UserTable__headerCellRole)}>Доступ</div>
          <div className={clsx(s.UserTable__headerCell, s.UserTable__headerCellEmail)}>Эл. почта</div>
          <div className={clsx(s.UserTable__headerCell, s.UserTable__headerCellActions)} />
        </Box>
      </div>
      <div className={s.UserTable__body}>
        {data.length === 0 && <NothingFound label="Пользователь не найден" />}
        {data.map((user) => {
          const nameFirstLetter = user.name[0].toUpperCase();

          if (nameFirstLetter !== currentFirstLetter) {
            currentFirstLetter = nameFirstLetter;
            return (
              <React.Fragment key={user.id}>
                <TableLetter key={currentFirstLetter} letter={currentFirstLetter} />
                <UserRow key={user.id} data={user} />
              </React.Fragment>
            );
          }

          return <UserRow key={user.id} data={user} />;
        })}
      </div>
    </div>
  );
}
