import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IDirection } from '../../../../store/interfaces/IDirection';
import { DeputyTable } from './DeputyCard';
import s from './DirectionCard.module.scss';

export interface DirectionCardProps {
  data: IDirection;
  showBudget: boolean;
  deputyName: string;
}

export function DirectionCard({ data, showBudget, deputyName }: DirectionCardProps) {
  return (
    <Link
      to={{
        search: `?deputy=${data.block.id}&direction=${data.id}`
      }}
      className={clsx(s.DirectionCard, {
        [s.DirectionCard_compact]: !showBudget
      })}
    >
      <div className={s.DirectionCard__header}>
        <div className={s.DirectionCard__title}>
          {data.order} {data.name}
        </div>
        <div className={s.DirectionCard__userName}>{deputyName}</div>
      </div>
      {showBudget ? (
        <DeputyTable budgetData={data.budgets} />
      ) : (
        <div className={s.DirectionCard__projects}>
          <div className={s.DirectionCard__label}>Проектов</div>
          <div className={s.DirectionCard__value}>{data.projectsCount}</div>
        </div>
      )}
    </Link>
  );
}
