import React, { CSSProperties, HTMLAttributes, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './UserPic.module.scss';

export interface UserPicProps extends HTMLAttributes<HTMLDivElement> {
  radius: number;
  fontSize: number;
  name: string;
  picture?: string;
  className?: string;
  color?: string;
}

export function UserPic({ radius, fontSize, name, picture, className, color, ...props }: UserPicProps) {
  const [isValidImage, setIsValidImage] = useState(true);

  const isPicture = isValidImage && picture;

  const nameAbbr = useMemo(() => {
    if (picture) {
      return '';
    }

    const nameArray = name.split(' ');

    if (nameArray.length === 0) {
      return '';
    } else if (nameArray.length === 1) {
      return nameArray[0][0];
    }

    return nameArray[0][0] + nameArray[1][0];
  }, [name, picture]);

  return (
    <div
      style={
        {
          width: radius * 2,
          height: radius * 2,
          fontSize: fontSize,
          '--accentColor': color
        } as CSSProperties
      }
      className={clsx(s.UserPic, className, {
        [s.UserPic_noAbbr]: isPicture
      })}
      {...props}
    >
      {!isPicture && <div className={s.UserPic__abbr}>{nameAbbr.toUpperCase()}</div>}
      {isPicture && (
        <img src={picture} alt={name} className={s.UserPic__picture} onError={() => setIsValidImage(false)} />
      )}
    </div>
  );
}
