import React from 'react';
import { DeputyCard } from '../../common/components/ui/Card/DeputyCard';
import { ICatalog } from '../../store/interfaces/ICatalog';
import { IDeputy } from '../../store/interfaces/IDeputy';
import { NothingFound } from '../../common/components/ui/NothingFound/NothingFound';
import s from './CatalogPage.module.scss';

export interface DeputyPageProps {
  catalogData?: ICatalog | null;
  deputiesList?: IDeputy[] | null;
  catalogId: number;
  showBudget: boolean;
}

export function DeputyPage({ deputiesList, showBudget }: DeputyPageProps) {
  return (
    <>
      {deputiesList?.length === 0 && <NothingFound label="Нет данных" />}
      <div className={s.CatalogPage__cards}>
        {deputiesList?.map((deputy) => (
          <DeputyCard key={deputy.id} data={deputy} isBudgetVisible={showBudget} />
        ))}
      </div>
    </>
  );
}
