import 'symbol-observable';

// :focus-visible polyfill
import 'focus-visible';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import './index.scss';

import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router';
import { ProstoreContext } from '@proscom/prostore-react';
import { LocalStorageStore } from '@proscom/prostore-local-storage';
import { AxiosClientsContext, AxiosClientsManager } from '@proscom/prostore-axios-react';
import { LocationStore, LocationProvider } from '@proscom/prostore-react-router';
import { AuthStore } from './store/AuthStore';
import { FeedbackStore } from './store/FeedbackStore';
import {
  LOCAL_STORAGE_KEY_AUTH,
  LOCAL_STORAGE_KEY_LAST_CATEGORY,
  LOCAL_STORAGE_KEY_LAST_GROUPS,
  LOCAL_STORAGE_KEY_LAST_SCROLL,
  LOCAL_STORAGE_KEY_SHOW_BUDGET,
  LOCAL_STORAGE_KEY_VIEW
} from './store/storageKeys';

import {
  STORE_AUTH,
  STORE_FEEDBACK,
  STORE_LOCAL_STORAGE,
  STORE_LOCATION,
  STORE_SHOW_BUDGET,
  STORE_VIEW
} from './store/stores';

import { createAppHistory } from './appHistory';
import { App } from './App';
import { CatalogViewStore } from './store/CatalogViewStore';
import { ShowBudgetStore } from './store/ShowBudgetStore';

const appHistory = createAppHistory();

const localStorageStore = new LocalStorageStore(window.localStorage, [
  LOCAL_STORAGE_KEY_AUTH,
  LOCAL_STORAGE_KEY_LAST_CATEGORY,
  LOCAL_STORAGE_KEY_LAST_GROUPS,
  LOCAL_STORAGE_KEY_LAST_SCROLL,
  LOCAL_STORAGE_KEY_SHOW_BUDGET,
  LOCAL_STORAGE_KEY_VIEW
]);

localStorageStore.registerListener();

const locationStore = new LocationStore({ history: appHistory });

const authStore = new AuthStore({ localStorageStore });
authStore.subscribe();

const client = axios.create();
const axiosContext = new AxiosClientsManager({ default: client });

client.interceptors.request.use((config) => {
  const Authorization = 'Bearer ' + authStore.state.authData?.accessToken;
  return { ...config, headers: { Authorization } };
});

client.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response?.status === 401) {
      return authStore.refreshAccessToken().then(() => client(err.config));
    } else {
      return Promise.reject(err);
    }
  }
);

const feedbackStore = new FeedbackStore({ locationStore, client });
feedbackStore.subscribe();

const viewStore = new CatalogViewStore();
viewStore.registerListener();

const showBudgetStore = new ShowBudgetStore();
showBudgetStore.registerListener();

const stores = {
  [STORE_AUTH]: authStore,
  [STORE_FEEDBACK]: feedbackStore,
  [STORE_LOCATION]: locationStore,
  [STORE_LOCAL_STORAGE]: localStorageStore,
  [STORE_VIEW]: viewStore,
  [STORE_SHOW_BUDGET]: showBudgetStore
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router history={appHistory}>
    <ProstoreContext.Provider value={stores}>
      <AxiosClientsContext.Provider value={axiosContext}>
        <LocationProvider storeOrName={STORE_LOCATION}>
          <App />
        </LocationProvider>
      </AxiosClientsContext.Provider>
    </ProstoreContext.Provider>
  </Router>
);
