import React, { ForwardedRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/icons/chevron-down-large.svg';
import { ReactComponent as CaretIcon } from '../../../../assets/icons/caret-down.svg';
import s from './Spoiler.module.scss';

export interface SpoilerProps extends React.HTMLProps<HTMLDivElement> {
  header: string;
  isOpen?: boolean;
  isCompact?: boolean;
  className?: string;
  classNames?: {
    header?: string;
    content?: string;
  };
}

export const Spoiler = React.forwardRef(
  (
    { header, isOpen = true, isCompact = false, children, className, classNames, ...props }: SpoilerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [isOpened, setIsOpened] = useState<boolean>(isOpen);

    useEffect(() => {
      setIsOpened(isOpen);
    }, [isOpen]);

    return (
      <div
        className={clsx(s.Spoiler, className, {
          [s.Spoiler_isOpened]: isOpened,
          [s.Spoiler_isCompact]: isCompact
        })}
        ref={ref}
        {...props}
      >
        <button className={clsx(s.Spoiler__header, classNames?.header)} onClick={() => setIsOpened(!isOpened)}>
          <div className={s.Spoiler__headerName}>{header}</div>
          <div className={s.Spoiler__headerChevron}>{isCompact ? <CaretIcon /> : <ChevronDownIcon />}</div>
        </button>
        {isOpened && <div className={clsx(s.Spoiler__content, classNames?.content)}>{children}</div>}
      </div>
    );
  }
);
