import { useMemo } from 'react';
import { IUseAxiosQueryOptions, useAxiosQuery } from '@proscom/prostore-axios-react/lib/es/useAxiosQuery';
import { ICatalog } from '../interfaces/ICatalog';

export interface CatalogQueryVars {}

export function useCatalog(catalogId: number, variables: CatalogQueryVars = {}) {
  const queryOptions: IUseAxiosQueryOptions<CatalogQueryVars | null, ICatalog> = useMemo(
    () => ({
      query: {
        url: `/api/catalog/${catalogId}`,
        method: 'get'
      }
    }),
    [catalogId]
  );

  return useAxiosQuery({
    queryOptions,
    variables
  });
}
