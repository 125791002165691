import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@proscom/ui-react';
import { Button, ButtonVariant } from '../Button/Button';
import { ReactComponent as SortIcon } from '../../../../assets/icons/sort.svg';
import { useQuery } from '../../../hooks/useQuery';
import { getValidSortValue } from '../../../../utils/catalog';
import { TCatalogSort } from '../../../../routes/index/CatalogsPage';
import { Dropdown } from './Dropdown';
import s from './CatalogCardDropdown.module.scss';

export interface CatalogSortDropdownProps extends React.HTMLProps<HTMLButtonElement> {}

export function CatalogSortDropdown({ children, ref, ...props }: CatalogSortDropdownProps) {
  const [query, changeQuery] = useQuery();

  const sortValue = getValidSortValue<TCatalogSort>(query?.sort, ['actual', 'non-actual']);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [refElement, setRefElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(refElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [4, 4]
        }
      }
    ]
  });

  const options = [
    {
      label: 'Показывать все',
      onClick: () => {
        changeQuery({
          sort: undefined
        });
      }
    },
    {
      label: 'Актуальные',
      onClick: () => {
        changeQuery({
          sort: 'actual'
        });
      }
    },
    {
      label: 'Неактуальные',
      onClick: () => {
        changeQuery({
          sort: 'non-actual'
        });
      }
    }
  ];

  useClickOutside({ current: popperElement }, () => {
    setIsDropdownVisible(false);
  });

  return (
    <>
      <Button
        variant={ButtonVariant.tertiary}
        ref={setRefElement}
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        className={sortValue && s.CatalogCardDropdownButton}
      >
        <SortIcon />
        {sortValue && <div>{sortValue === 'actual' ? 'Фильтр: Актуальные' : 'Фильтр: Неактуальные'}</div>}
      </Button>

      {isDropdownVisible && (
        <Dropdown
          options={options}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          onClose={() => setIsDropdownVisible(false)}
        />
      )}
    </>
  );
}
