import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import clsx from 'clsx';
import { Toggle } from '../../../common/components/ui/Toggle/Toggle';
import { Box } from '../../../common/components/ui/Box/Box';
import { ICatalog } from '../../../store/interfaces/ICatalog';
import { ShowBudgetStore } from '../../../store/ShowBudgetStore';
import { STORE_AUTH, STORE_SHOW_BUDGET } from '../../../store/stores';
import { AuthStore } from '../../../store/AuthStore';
import { getBudgetInfo } from '../../../utils/budget';
import { CatalogHeaderStat } from './CatalogHeaderStat';
import s from './CatalogHeader.module.scss';

export interface CatalogHeaderProps {
  data: ICatalog | null;
  showBudget: boolean;
}

export function CatalogHeader({ data, showBudget }: CatalogHeaderProps) {
  const [isCompressedDescription, setIsCompressedDescription] = useState<boolean | null>(null);
  const [_, showBudgetStore] = useStore<ShowBudgetStore>(STORE_SHOW_BUDGET);
  const [authState, __] = useStore<AuthStore>(STORE_AUTH);

  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (descriptionRef.current && descriptionRef.current.offsetHeight > 60) {
      // 20 line-height
      setIsCompressedDescription(true);
    }
  }, [descriptionRef.current, data?.description]);

  return (
    <Box className={s.CatalogHeader}>
      <div className={s.CatalogHeader__header}>
        <div className={s.CatalogHeader__title}>{data?.name}</div>
        <div
          className={clsx(s.CatalogHeader__subheader, {
            [s.CatalogHeader__subheader_compressed]: isCompressedDescription
          })}
          ref={descriptionRef}
        >
          {data?.description}
        </div>
        {isCompressedDescription && (
          <button className={s.CatalogHeader__subheaderExpand} onClick={() => setIsCompressedDescription(false)}>
            Еще
          </button>
        )}
        {isCompressedDescription === false && (
          <button className={s.CatalogHeader__subheaderExpand} onClick={() => setIsCompressedDescription(true)}>
            Свернуть
          </button>
        )}
      </div>
      <div className={s.CatalogHeader__stats}>
        {showBudget &&
          data?.budgets.map((budget) => {
            const [budgetLabel, budgetValue] = getBudgetInfo(budget);

            return <CatalogHeaderStat key={budget.year} name={budgetLabel} value={budgetValue} />;
          })}
      </div>
      {authState.authData?.user.accessToFinance && (
        <div className={s.CatalogHeader__toggle}>
          <Toggle checked={showBudgetStore.getValue()} onChange={() => showBudgetStore.toggleView()}>
            Показывать данные по бюджету
          </Toggle>
        </div>
      )}
    </Box>
  );
}
