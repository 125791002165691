import { useEffect } from 'react';

export function usePreventBodyScroll() {
  useEffect(() => {
    // почему-то offsetWidth === clientWidth, из-за этого не получается сделать нормально. Возможно проблема макоси, нужно поисследовать
    const bodyWidthWithScrollbar = document.body.offsetWidth;
    document.body.style.overflow = 'hidden';
    const bodyWidthWithoutScrollbar = document.body.offsetWidth;
    document.body.style.paddingRight = bodyWidthWithoutScrollbar - bodyWidthWithScrollbar + 'px';

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, []);
}
