import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '../Box/Box';
import { ReactComponent as BackIcon } from '../../../../assets/icons/back.svg';
import s from './BackLink.module.scss';

export interface BackLinkProps extends React.HTMLProps<HTMLDivElement> {
  to: string;
}

export function BackLink({ children, to = '/' }: BackLinkProps) {
  return (
    <div className={s.BackLink}>
      <Box>
        <Link to={to} className={s.BackLink__link}>
          <div className={s.BackLink__icon}>
            <BackIcon />
          </div>
          {children}
        </Link>
      </Box>
    </div>
  );
}
