import { Subscribable, Unsubscribable } from 'rxjs';

export class SubscriptionManager {
  subscriptions: Unsubscribable[] = [];

  subscribe = <T>(obs$: Subscribable<T>, callback?: (value: T) => void) => {
    const sub = obs$.subscribe(callback);
    this.subscriptions.push(sub);
    return sub;
  };

  unsubscribe(sub: Unsubscribable) {
    sub.unsubscribe();
    const index = this.subscriptions.indexOf(sub);
    if (index >= 0) {
      this.subscriptions.splice(index, 1);
    }
  }

  destroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.subscriptions.length = 0;
  }
}
