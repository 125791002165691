import React, { ReactNode } from 'react';
import clsx from 'clsx';
import s from './ModalGroup.module.scss';

export interface ModalGroupProps extends React.HTMLProps<HTMLDivElement> {
  header?: string;
  actions?: ReactNode;
}

export function ModalGroup({ header, children, className, actions, ...props }: ModalGroupProps) {
  return (
    <div className={clsx(s.ModalGroup, className)} {...props}>
      {header && (
        <div className={s.ModalGroup__header}>
          {header}
          {actions && <div className={s.ModalGroup__actions}>{actions}</div>}
        </div>
      )}
      {children}
    </div>
  );
}
