import { parse as parseUrl } from 'url';
import { createBrowserHistory, Location } from 'history';
import { getParentUrl } from '@proscom/ui-utils';

export type LocationState = { depth?: number };
export type UrlOrLocation = string | Location<LocationState>;

function increaseDepth<T extends { depth?: number }>(state?: T): T {
  if (!state) {
    return {
      depth: 1
    } as T;
  }
  return {
    ...state,
    depth: (state.depth || 0) + 1
  } as T;
}

function getDepth(location: Location<LocationState>): number {
  return location.state?.depth || 0;
}

export function createAppHistory(
  onChange: ((params: { location: Location; depth: number }) => any) | undefined = undefined
) {
  const baseHistory = createBrowserHistory<LocationState>();

  if (onChange) {
    baseHistory.listen((location: Location<LocationState>) => {
      onChange({
        location,
        depth: getDepth(location)
      });
    });
  }

  function getLocation() {
    return baseHistory.location;
  }

  function resolveParentUrl() {
    return getParentUrl(getLocation().pathname);
  }

  function goBack(parent: null | UrlOrLocation = null) {
    const curLocation = getLocation();
    const appDepth = getDepth(curLocation);
    if (appDepth > 0) {
      baseHistory.goBack();
    } else {
      let url = parent;
      if (url === null) {
        url = resolveParentUrl();
      }

      if (typeof url === 'string') {
        console.log(url);
        const parsed = parseUrl(url);
        baseHistory.push({
          state: curLocation.state,
          // @ts-ignore
          pathname: parsed.pathname,
          // @ts-ignore
          search: parsed.search,
          // @ts-ignore
          hash: parsed.hash
        });
      } else {
        baseHistory.push({
          // @ts-ignore
          state: curLocation.state,
          ...url
        });
      }
    }
  }

  function pushState(state: LocationState) {
    const curLocation = getLocation();
    baseHistory.push({
      state: {
        ...increaseDepth(curLocation.state),
        ...(state || {})
      }
    });
  }

  function push(location: UrlOrLocation) {
    const curLocation = getLocation();
    const state = increaseDepth(curLocation.state);
    if (typeof location === 'string') {
      console.log(location);
      const parsed = parseUrl(location);
      baseHistory.push({
        state,
        // @ts-ignore
        pathname: parsed.pathname,
        // @ts-ignore
        search: parsed.search,
        // @ts-ignore
        hash: parsed.hash
      });
    } else {
      baseHistory.push({
        // @ts-ignore
        state,
        ...location
      });
    }
  }

  function replace(location: UrlOrLocation) {
    const curLocation = getLocation();
    const state = curLocation.state;
    if (typeof location === 'string') {
      console.log(location);
      const parsed = parseUrl(location);
      baseHistory.replace({
        state,
        // @ts-ignore
        pathname: parsed.pathname,
        // @ts-ignore
        search: parsed.search,
        // @ts-ignore
        hash: parsed.hash
      });
    } else {
      baseHistory.replace({
        // @ts-ignore
        state,
        ...location
      });
    }
  }

  return {
    ...baseHistory,
    goBack,
    push,
    replace,
    getLocation,
    pushState
  };
}
