import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box } from '../../common/components/ui/Box/Box';
import { BackLink } from '../../common/components/ui/BackLink/BackLink';
import { useCatalog } from '../../store/query/useCatalog';
import { multiQueryLoader } from '../../common/components/utils/queryLoader';
import { useProjects } from '../../store/query/useProjects';
import { useDirections } from '../../store/query/useDirections';
import { useDeputies } from '../../store/query/useDeputies';
import { IDirection } from '../../store/interfaces/IDirection';
import { useQuery } from '../../common/hooks/useQuery';
import { ICatalogData } from '../_layouts/TopBar/TopBar';
import { useBudgetAccess } from '../../common/hooks/useBudgetAccess';
import { ProjectsPage } from './ProjectsPage';
import { DeputyPage } from './DeputyPage';
import { CatalogHeader } from './header/CatalogHeader';
import { CatalogFilter } from './filter/CatalogFilter';
import { DirectionsPage } from './DirectionsPage';
import { DeputyModal } from './modal/DeputyModal';
import s from './CatalogPage.module.scss';

export interface CatalogBasePageProps {
  match: { params: { catalogId: number; projectId?: number } };
  onCatalogLoaded?: (value?: ICatalogData) => void;
}

export function CatalogBasePage({ match, onCatalogLoaded }: CatalogBasePageProps) {
  const showBudget = useBudgetAccess();

  const catalogId = Number(match.params.catalogId);
  const catalogQuery = useCatalog(Number(catalogId));
  const catalogData = catalogQuery.state.data;

  useEffect(() => {
    onCatalogLoaded?.(
      catalogData
        ? {
            id: catalogData?.id,
            name: catalogData?.name
          }
        : undefined
    );
  }, [catalogData, onCatalogLoaded]);

  const deputiesQuery = useDeputies({ catalogId });
  const deputiesData = deputiesQuery.state.data;

  const directionsQuery = useDirections({ catalogId });
  const directionsData = directionsQuery.state.data;

  const projectsQuery = useProjects({ catalogId });
  const projectsData = projectsQuery.state.data;

  const [query, _] = useQuery();
  const deputyId = +query?.deputy || 0;
  const search = query?.q;

  const [currentDeputy, currentDeputyDirections] = useMemo(() => {
    const deputy = deputiesData?.find((deputy) => deputy.id === deputyId);
    const directions = directionsData?.filter((direction: IDirection) => direction.block.id === deputy?.id);

    const groupedProjectsByDirections =
      directions?.map((direction) => {
        const projects = projectsData?.filter(
          (project) => project.block.id === deputy?.id && project.direction.id === direction.id
        );

        return {
          ...direction,
          projects: projects || []
        };
      }) || [];

    return [deputy, groupedProjectsByDirections];
  }, [deputyId, deputiesData, directionsData, projectsData]);

  return (
    <Box width={1098} className={s.CatalogPage}>
      <BackLink to="/">Каталоги проектов</BackLink>
      {multiQueryLoader([catalogQuery, deputiesQuery, directionsQuery, projectsQuery]) || (
        <>
          <CatalogHeader data={catalogData} showBudget={showBudget} />
          <CatalogFilter catalogId={catalogId} />
          {currentDeputy && (
            <DeputyModal deputyData={currentDeputy} directionsData={currentDeputyDirections} showBudget={showBudget} />
          )}
          {search ? (
            <ProjectsPage
              projectsList={projectsData}
              directionsList={directionsData}
              catalogData={catalogData}
              showBudget={showBudget}
              catalogId={catalogId}
              deputiesList={deputiesData}
            />
          ) : (
            <Switch>
              <Route path="/catalog/:catalogId/project">
                <ProjectsPage
                  projectsList={projectsData}
                  directionsList={directionsData}
                  catalogData={catalogData}
                  showBudget={showBudget}
                  catalogId={catalogId}
                  deputiesList={deputiesData}
                />
              </Route>
              <Route path="/catalog/:catalogId/direction">
                <DirectionsPage
                  directionsList={directionsData}
                  catalogData={catalogData}
                  showBudget={showBudget}
                  catalogId={catalogId}
                  deputiesList={deputiesData}
                />
              </Route>
              <Route path="/">
                <DeputyPage
                  catalogData={catalogData}
                  showBudget={showBudget}
                  catalogId={catalogId}
                  deputiesList={deputiesData}
                />
              </Route>
            </Switch>
          )}
        </>
      )}
    </Box>
  );
}
