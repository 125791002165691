import React, { useEffect, useState } from 'react';
import { RegisterOptions, useFormContext, Controller } from 'react-hook-form';
import { Toggle, ToggleProps } from '../Toggle/Toggle';

export interface FormToggleProps extends ToggleProps {
  name: string;
  registerOptions?: RegisterOptions;
}

export function FormToggle({ name, onChange, registerOptions, defaultValue, checked, ...props }: FormToggleProps) {
  const { register, unregister, setError, errors, setValue, watch, control } = useFormContext<{
    [name: string]: string;
  }>();
  const fieldData = watch(name) || '';

  useEffect(() => {
    register({ name, ...registerOptions });
    return () => unregister(name);
  }, [name, register, registerOptions, unregister]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        return <Toggle name={name} onChange={onChange} checked={!!value} {...props} />;
      }}
    />
  );
}
