import clsx from 'clsx';
import React, { ReactNode } from 'react';
import s from './SubmitButton.module.scss';

export interface ISubmitButtonProps {
  disabled?: boolean;
  className?: string;
  children: string | ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export function SubmitButton({ disabled, className, children, leftIcon, rightIcon }: ISubmitButtonProps) {
  return (
    <button
      type={'submit'}
      disabled={disabled}
      className={clsx(s.SubmitButton, { [s.SubmitButton_disabled]: disabled }, className)}
    >
      {leftIcon && <span className={s.SubmitButton__leftIcon}>{leftIcon}</span>}
      {children}
      {rightIcon && <span className={s.SubmitButton__rightIcon}>{rightIcon}</span>}
    </button>
  );
}
