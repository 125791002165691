import React from 'react';
import s from './CatalogHeaderStat.module.scss';

export interface ICatalogHeaderStat {
  name: string;
  value: string;
}

export function CatalogHeaderStat({ name, value }: ICatalogHeaderStat) {
  return (
    <div className={s.CatalogHeaderStat}>
      <div className={s.CatalogHeaderStat__label}>{name}</div>
      <div className={s.CatalogHeaderStat__value}>{value} млн</div>
    </div>
  );
}
