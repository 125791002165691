import React, { useEffect, useState } from 'react';
import { RegisterOptions, useFormContext, Controller } from 'react-hook-form';
import { TextInput, TextInputProps } from '../Input/TextInput';

export interface ControlledInputProps extends TextInputProps {
  name: string;
  registerOptions?: RegisterOptions;
}

export function FormTextInput({ name, onChange, registerOptions, defaultValue, ...props }: ControlledInputProps) {
  const { register, unregister, setError, errors, setValue, watch, control } = useFormContext<{
    [name: string]: string;
  }>();
  const fieldData = watch(name) || '';

  useEffect(() => {
    register({ name, ...registerOptions });
    return () => unregister(name);
  }, [name, register, registerOptions, unregister]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        return (
          <TextInput
            name={name}
            value={value}
            onChange={onChange}
            errorText={errors[name] && errors[name]?.message}
            {...props}
          />
        );
      }}
    />
  );
}
