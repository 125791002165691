import axios, { AxiosInstance } from 'axios';
import { BehaviorStore } from '@proscom/prostore';
import { ILocationStoreState, LocationStore } from '@proscom/prostore-react-router';

import { SubscriptionManager } from '../utils/helpers/SubscriptionManager';

export interface FeedbackStoreState {
  feedbackForm: boolean;
  loading: boolean;
  success: boolean;
  error: string | null;
}

export interface FeedbackStoreArgs {
  locationStore: LocationStore;
  client: AxiosInstance;
}

export class FeedbackStore extends BehaviorStore<FeedbackStoreState> {
  locationStore: LocationStore;
  client: AxiosInstance;
  subscription = new SubscriptionManager();

  constructor({ locationStore, client }: FeedbackStoreArgs) {
    super({
      feedbackForm: false,
      loading: false,
      success: false,
      error: null
    });

    this.locationStore = locationStore;
    this.client = client;
  }

  subscribe() {
    this.subscription.subscribe(this.locationStore.state$, this._handleLocationStoreStateChange);
  }

  unsubscribe() {
    this.subscription.destroy();
  }

  _handleLocationStoreStateChange = ({ query }: ILocationStoreState) => {
    const feedbackForm = !!query && 'feedbackForm' in query;
    if (feedbackForm !== this.state.feedbackForm) {
      this.setState({ feedbackForm });
      if (!feedbackForm) this.setState({ success: false, error: null });
    }
  };

  sendFeedback(message: string) {
    if (!this.state.loading) {
      this.setState({ loading: true });

      (window.location.pathname === '/login'
        ? axios.post('/api/form/sendWithoutAuth', { message })
        : this.client.post('/api/form/send', {
            message,
            pathname: window.location.pathname
          })
      )
        .then(() => {
          this.setState({ loading: false, success: true });
        })
        .catch(() => {
          this.setState({
            loading: false,
            error: 'Не удалось отправить форму.'
          });
        });
    }
  }
}
