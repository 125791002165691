import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SidebarModal } from '../../../common/components/ui/Modal/SidebarModal';
import { useQuery } from '../../../common/hooks/useQuery';
import { BudgetTable } from '../../../common/components/ui/Table/BudgetTable';
import { UserPic } from '../../../common/components/ui/UserPic/UserPic';
import { Spoiler } from '../../../common/components/ui/Spoiler/Spoiler';
import { BudgetCompactTable } from '../../../common/components/ui/Table/BudgetCompactTable';
import { ProjectBudgetCard } from '../../../common/components/ui/Card/ProjectBudgetCard';
import { IDeputy } from '../../../store/interfaces/IDeputy';
import { IDirection } from '../../../store/interfaces/IDirection';
import { IProject } from '../../../store/interfaces/IProject';
import { getBlockColor as getDeputyColor } from '../../../utils/blockColors';
import { directionSort, makeDirectionName } from '../../../utils/catalog';
import { LOCAL_STORAGE_KEY_DEPUTY_SCROLLTOP } from '../../../store/storageKeys';
import { getBlockImageUrl } from '../../../utils/block';
import s from './DeputyModal.module.scss';

export interface DeputyModalProps {
  deputyData: IDeputy;
  directionsData: IDirectionWithProjects[];
  showBudget: boolean;
}

export interface IDirectionWithProjects extends IDirection {
  projects: IProject[];
}

export function DeputyModal({ deputyData, directionsData, showBudget }: DeputyModalProps) {
  const [query, changeQuery] = useQuery();
  const [scrollTopPosition, setScrollTopPosition] = useState(0);
  const targetDirectionRef = useRef<HTMLDivElement>(null);

  const deputyId = +query?.deputy;
  const directionId = +query?.direction;

  const deputyImage = useMemo(() => {
    return getBlockImageUrl(deputyData);
  }, [deputyData]);

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEY_DEPUTY_SCROLLTOP)) {
      const localStorageData = localStorage.getItem(LOCAL_STORAGE_KEY_DEPUTY_SCROLLTOP)?.split(';');

      if (localStorageData && parseInt(localStorageData[0]) === deputyId) {
        setScrollTopPosition(parseInt(localStorageData[1]));
      }
    }
  }, [deputyId]);

  useEffect(() => {
    if (targetDirectionRef.current) {
      setScrollTopPosition(targetDirectionRef.current.offsetTop - 110); //110 - высота шапки модалки + отступ
    }
  }, [deputyId, directionId, deputyData, targetDirectionRef]);

  const handleClose = useCallback(() => {
    changeQuery({
      deputy: undefined,
      direction: undefined
    });

    localStorage.setItem(LOCAL_STORAGE_KEY_DEPUTY_SCROLLTOP, '');
  }, [changeQuery]);

  const handleScroll = useCallback(
    (e: any) => {
      if (directionId) {
        changeQuery({
          direction: undefined
        });
      }
      localStorage.setItem(LOCAL_STORAGE_KEY_DEPUTY_SCROLLTOP, `${deputyId};${e.target.scrollTop}`);
    },
    [deputyId, LOCAL_STORAGE_KEY_DEPUTY_SCROLLTOP, changeQuery, directionId]
  );

  return (
    <SidebarModal width={584} onClose={handleClose} scrollTop={scrollTopPosition} onScroll={handleScroll}>
      <div className={s.DeputyModal} style={{ '--accentColor': getDeputyColor(deputyData.id) } as CSSProperties}>
        <div className={s.DeputyModal__user}>
          <div className={s.DeputyModal__userPicture}>
            <UserPic picture={deputyImage} radius={20} fontSize={16} name={deputyData.name} />
          </div>
          <div className={s.DeputyModal__userName}>{deputyData.name}</div>
        </div>
        {showBudget && (
          <div className={s.DeputyModal__budget}>
            <BudgetTable data={deputyData.budgets} />
          </div>
        )}
        <div className={s.DeputyModal__directions}>
          {directionsData
            ?.sort((a, b) => directionSort(makeDirectionName(a.order, a.name), makeDirectionName(b.order, b.name)))
            .map((direction) => {
              return (
                <Spoiler
                  key={direction.id}
                  isCompact
                  header={`${direction.order} ${direction.name}`}
                  ref={direction.id === directionId ? targetDirectionRef : undefined}
                >
                  {showBudget && <BudgetCompactTable data={direction.budgets} />}
                  <div className={s.DeputyModal__projects}>
                    {direction.projects?.map((project) => (
                      <ProjectBudgetCard key={project.id} data={project} showBudget={showBudget} />
                    ))}
                  </div>
                </Spoiler>
              );
            })}
        </div>
      </div>
    </SidebarModal>
  );
}
