import React from 'react';
import clsx from 'clsx';
import { TView } from '../../../common/components/ui/Card/CatalogCard';
import s from './CatalogList.module.scss';

export interface CatalogListProps extends React.HTMLProps<HTMLDivElement> {
  view: TView;
}

export function CatalogList({ view, children }: CatalogListProps) {
  return (
    <div
      className={clsx(s.CatalogList, {
        [s.CatalogList_grid]: view === 'grid',
        [s.CatalogList_list]: view === 'list'
      })}
    >
      {children}
    </div>
  );
}
