import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react/lib/es/useAsyncOperation';
import { IUser } from '../interfaces/IUser';
import { IUserCreateVars } from './useUserCreate';

export interface IUserUpdateVars extends IUserCreateVars {}

export function useUserUpdate() {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (userId: number, data: IUserUpdateVars): Promise<IUser> => {
      return client
        .put(`/api/user/${userId}`, JSON.parse(JSON.stringify(data)))
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error(err);
          throw new Error('Возникла ошибка при сохранении данных пользователя. Попробуйте еще раз');
        });
    },
    {
      singleton: true
    }
  );
}
