import React from 'react';
import { Modal, ModalProps } from '../../../common/components/ui/Modal/Modal';
import DeleteUserImage from '../../../assets/images/delete-user.svg';
import { Button, ButtonColor, ButtonVariant } from '../../../common/components/ui/Button/Button';
import { useUserRemove } from '../../../store/mutation/useUserRemove';
import s from './DeleteUserModal.module.scss';

export interface DeleteUserModalProps extends ModalProps {
  userId: number;
  onApprove: () => void;
}

export function DeleteUserModal({ userId, onApprove, ...props }: DeleteUserModalProps) {
  const deleteUserRequest = useUserRemove();

  function handleOnApproveClick() {
    deleteUserRequest.run(userId).then((response) => {
      onApprove?.();
      props.onClose?.();
    });
  }

  return (
    <Modal {...props}>
      <div className={s.DeleteUserModal}>
        <img src={DeleteUserImage} alt="Удаление" />
        <div className={s.DeleteUserModal__header}>Вы хотите удалить пользователя?</div>
        <div className={s.DeleteUserModal__description}>Пользователь будет безвозвратно удален</div>
        <div className={s.DeleteUserModal__actions}>
          <Button
            className={s.DeleteUserModal__approveAction}
            color={ButtonColor.primary}
            variant={ButtonVariant.primary}
            onClick={handleOnApproveClick}
          >
            Удалить
          </Button>
          <Button
            className={s.DeleteUserModal__undoAction}
            onClick={props?.onClose}
            variant={ButtonVariant.secondary}
            color={ButtonColor.secondary}
          >
            Отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
}
