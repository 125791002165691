import React from 'react';
import clsx from 'clsx';
import s from './Box.module.scss';

export function Box({ children, width = 1098, style, className }: React.HTMLProps<HTMLDivElement>) {
  return (
    <div className={clsx(s.Box, className)} style={{ ...style, maxWidth: width }}>
      {children}
    </div>
  );
}
