import React from 'react';
import s from './Dropdown.module.scss';

export interface DropdownOption {
  label: string;
  onClick: () => void;
}

export interface DropdownProps extends React.HTMLProps<HTMLDivElement> {
  options: DropdownOption[];
  onClose?: () => void;
}

export const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
  ({ options, className, onClose, ...props }, ref) => {
    return (
      <div className={s.Dropdown} ref={ref} {...props}>
        {options.map((option, optionIndex) => (
          <button
            key={optionIndex}
            className={s.Dropdown__item}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              option.onClick();
              onClose?.();
            }}
          >
            {option.label}
          </button>
        ))}
      </div>
    );
  }
);
