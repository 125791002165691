import axios from 'axios';
import { isEqual } from 'lodash-es';
import { singleton } from '@proscom/ui-utils';
import { BehaviorStore } from '@proscom/prostore';
import { LocalStorageStore } from '@proscom/prostore-local-storage';
import { SubscriptionManager } from '../utils/helpers/SubscriptionManager';
import { LOCAL_STORAGE_KEY_AUTH } from './storageKeys';

export const ERROR_REFRESH_TOKEN_INVALID = 'Сессия истекла. Пожалуйста, войдите заново.';
export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export type AuthData = {
  user: {
    id: number;
    email: string;
    name: string;
    role: Role;
    accessToFinance: boolean;
  };
  accessToken: string;
  refreshToken: string;
} | null;

export interface AuthStoreState {
  authData: AuthData;
  loading: boolean;
  error: string | null;
}

export interface AuthStoreArgs {
  localStorageStore: LocalStorageStore;
}

export class AuthStore extends BehaviorStore<AuthStoreState> {
  localStorageStore: LocalStorageStore;
  subscription = new SubscriptionManager();

  constructor({ localStorageStore }: AuthStoreArgs) {
    super({ authData: null, loading: false, error: null });
    this.localStorageStore = localStorageStore;
  }

  subscribe() {
    this.subscription.subscribe(this.localStorageStore.get$(LOCAL_STORAGE_KEY_AUTH), this._handleLocalStorageChange);
  }

  unsubscribe() {
    this.subscription.destroy();
  }

  _handleLocalStorageChange = (authData: AuthData) => {
    if (!isEqual(authData, this.state.authData)) {
      this.setState({ authData });
    }
  };

  _saveToLocalStorage(authData: AuthData) {
    this.localStorageStore.setItem(LOCAL_STORAGE_KEY_AUTH, authData);
  }

  _setAuthenticationData(authData: AuthData) {
    this.setState({ authData });
    this._saveToLocalStorage(authData);
  }

  _setError(error: string) {
    this.setState({ error });
  }

  _performTokenRefresh() {
    if (!this.state.authData) return Promise.reject();

    return axios
      .post('/api/useRefreshToken', { refreshToken: this.state.authData.refreshToken })
      .then((response) => {
        this._setAuthenticationData(response.data);
      })
      .catch((err) => {
        if (err.response) {
          this.logOut();
          this._setError(ERROR_REFRESH_TOKEN_INVALID);
        }

        return Promise.reject(err);
      });
  }

  refreshAccessToken = singleton(() => this._performTokenRefresh());

  logOut() {
    this._setAuthenticationData(null);
  }

  logIn(email: string, password: string) {
    if (!this.state.loading) {
      if (!email || !password) return this._setError('Введите почту и пароль.');

      this.setState({ loading: true });

      axios
        .post('/api/signIn', { email, password })
        .then((response) => {
          this.setState({ loading: false, error: null });
          this._setAuthenticationData(response.data);
        })
        .catch((err) => {
          this.setState({
            loading: false,
            error: err.response?.status === 401 ? 'Неверное имя пользователя или пароль.' : 'Произошла ошибка.'
          });
        });
    }
  }
}
