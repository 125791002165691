import React, { useEffect, useRef, useState } from 'react';
import { SidebarModal, SidebarModalProps } from '../../../common/components/ui/Modal/SidebarModal';
import { ModalHeader } from '../../../common/components/ui/Modal/ModalHeader';
import { Button, ButtonColor, ButtonVariant } from '../../../common/components/ui/Button/Button';
import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg';
import { IBaseCatalog } from '../../../store/interfaces/ICatalog';
import { useCatalogUpdate } from '../../../store/mutation/useCatalogUpdate';
import { FormErrorMessage } from '../../../common/components/ui/Form/FormErrorMessage';
import { CatalogDataForm, TCatalogFormData } from './CatalogDataForm';
import s from './CatalogModal.module.scss';

export interface CatalogModalProps extends Omit<SidebarModalProps, 'data'> {
  catalogId: number;
  data: IBaseCatalog;
  onSubmit?: () => void;
}

export function CatalogModal({ catalogId, data, onClose, onSubmit }: CatalogModalProps) {
  const [errorText, setErrorText] = useState(null);
  const formSubmitRef = useRef<HTMLInputElement>(null);

  const addCatalogRequest = useCatalogUpdate();

  function handleSubmitForm(formData: TCatalogFormData) {
    addCatalogRequest
      .run(catalogId, {
        name: formData.title,
        date: formData.date.toISOString(),
        description: formData.short_description,
        isActual: Boolean(formData.is_actual)
      })
      .then(() => {
        onClose?.();
        onSubmit?.();
      })
      .catch((err) => {
        setErrorText(err.message);
      });
  }

  useEffect(() => {
    setErrorText(null);
  }, [catalogId, data]);

  function handleSaveClick() {
    if (formSubmitRef.current) {
      formSubmitRef.current.click();
    }
  }

  return (
    <SidebarModal onClose={onClose} className={s.CatalogModal}>
      <ModalHeader
        header={data.name}
        afterHeader={<div className={s.CatalogModal__headerDescription}>{data.description}</div>}
      >
        <Button
          variant={ButtonVariant.primary}
          color={ButtonColor.primary}
          leftIcon={<SaveIcon />}
          onClick={() => handleSaveClick()}
        >
          Сохранить
        </Button>
        <Button variant={ButtonVariant.secondary} color={ButtonColor.primary} onClick={onClose}>
          Отменить
        </Button>
      </ModalHeader>

      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}

      <CatalogDataForm
        formSubmitRef={formSubmitRef}
        onSubmit={handleSubmitForm}
        data={{
          title: data.name,
          short_description: data.description,
          is_actual: data.isActual,
          date: new Date(data.date)
        }}
      />
    </SidebarModal>
  );
}
