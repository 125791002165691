import React, { ReactNode } from 'react';
import s from './ModalBlock.module.scss';

export interface ModalBlockProps {
  header: string;
  children: ReactNode | ReactNode[];
}

export function ModalBlock({ header, children }: ModalBlockProps) {
  return (
    <div className={s.ModalBlock}>
      <div className={s.ModalBlock__header}>{header}</div>
      <div className={s.ModalBlock__content}>{children}</div>
    </div>
  );
}
