import clsx from 'clsx';
import React, { HTMLAttributes, ReactNode } from 'react';
import s from './Button.module.scss';

export enum ButtonColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary'
}

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary'
}

export interface ButtonProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'color' | 'type'> {
  variant?: ButtonVariant;
  color?: ButtonColor;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onlyIcon?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = ButtonVariant.primary,
      color = ButtonColor.secondary,
      className,
      children,
      leftIcon,
      rightIcon,
      onlyIcon,
      type = 'button',
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(s.Button, className, s[`Button_color_${color}`], {
          [s.Button_variant_secondary]: variant === ButtonVariant.secondary,
          [s.Button_variant_tertiary]: variant === ButtonVariant.tertiary,
          [s.Button_variant_quaternary]: variant === ButtonVariant.quaternary,
          [s.Button_onlyIcon]: onlyIcon,
          [s.Button_withIcon]: leftIcon || rightIcon
        })}
        type={type}
        {...props}
      >
        {leftIcon && <span className={s.Button__leftIcon}>{leftIcon}</span>}
        {children}
        {rightIcon && <span className={s.Button__rightIcon}>{rightIcon}</span>}
      </button>
    );
  }
);
