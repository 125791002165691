import { useEffect } from 'react';
import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useStoreState } from '@proscom/prostore-react';
import { ILocationStoreState } from '@proscom/prostore-react-router';
import { STORE_LOCATION } from '../../../store/stores';

export function ActivityLogger() {
  const axiosClient = useContextAxiosClient('default');
  const locationState = useStoreState<ILocationStoreState>(STORE_LOCATION);
  const pathname = locationState.location?.pathname;

  useEffect(() => {
    if (pathname) axiosClient.post('/api/users/activity', { activity: 'VISIT', pathname }).catch(() => {}); // do nothing
  }, [axiosClient, pathname]);

  return null;
}
