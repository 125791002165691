import React, { useEffect } from 'react';
import { RegisterOptions, useFormContext, Controller } from 'react-hook-form';
import { DateInput, DateInputProps } from '../Input/DateInput';

export interface ControlledInputProps extends DateInputProps {
  name: string;
  registerOptions?: RegisterOptions;
}

export function FormDateInput({
  name,
  onChange,
  registerOptions,
  defaultValue,
  value,
  ...props
}: ControlledInputProps) {
  const { register, unregister, control } = useFormContext<{
    [name: string]: string;
  }>();

  useEffect(() => {
    register({ name, ...registerOptions });
    return () => unregister(name);
  }, [name, register, registerOptions, unregister]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        return <DateInput name={name} value={value} onChange={onChange} {...props} />;
      }}
    />
  );
}
