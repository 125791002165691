import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react/lib/es/useAsyncOperation';
import { IBaseCatalog } from '../interfaces/ICatalog';

export interface ICatalogCreateVars extends Omit<IBaseCatalog, 'id' | 'folderName'> {}

export function useCatalogCreate() {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (data: ICatalogCreateVars): Promise<IBaseCatalog> => {
      return client
        .post('/api/catalog', data)
        .then((response) => response.data)
        .catch((err) => {
          console.error(err);
          throw new Error('Не удалось создать новый каталог. Повторите еще раз');
        });
    },
    {
      singleton: true
    }
  );
}
