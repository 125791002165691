import React, { ReactNode } from 'react';
import s from './ModalHeader.module.scss';

export interface ModalHeaderProps extends React.HTMLProps<HTMLDivElement> {
  header: string;
  beforeHeader?: ReactNode;
  afterHeader?: ReactNode;
}

export function ModalHeader({ header, beforeHeader, afterHeader, children, ...props }: ModalHeaderProps) {
  return (
    <div className={s.ModalHeader} {...props}>
      {beforeHeader}
      <div className={s.ModalHeader__header}>{header}</div>
      {afterHeader}
      {children && <div className={s.ModalHeader__actions}>{children}</div>}
    </div>
  );
}
