import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react/lib/es/useAsyncOperation';
import { IBaseCatalog } from '../interfaces/ICatalog';

export function useCatalogRemove() {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (catalogId: number): Promise<IBaseCatalog> => {
      return client
        .delete(`/api/catalog/${catalogId}`)
        .then((response) => response.data)
        .catch((err) => {
          console.error(err);
        });
    },
    {
      singleton: true
    }
  );
}
