import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react/lib/es/useAsyncOperation';
import { IUser } from '../interfaces/IUser';

export function useUserRemove() {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (userId: number): Promise<IUser> => {
      return client
        .delete(`/api/user/${userId}`)
        .then((response) => response.data)
        .catch((err) => {
          console.error(err);
        });
    },
    {
      singleton: true
    }
  );
}
