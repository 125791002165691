import { IBudget } from '../store/interfaces/IBudget';
import { IProject } from '../store/interfaces/IProject';
import { IDeputy } from '../store/interfaces/IDeputy';
import { IDirection } from '../store/interfaces/IDirection';

export function getValidSortValue<T>(currentValue: T, validValues: T[]): T | undefined {
  return validValues.indexOf(currentValue) >= 0 ? currentValue : undefined;
}

export function getBudgetYears(budget: IBudget[]): IBudget[] {
  return budget?.sort((a, b) => a.year - b.year) || [];
}

export function computeTotalBudget(directions: { [directionName: string]: IProject[] }): IBudget[] {
  let result: IBudget[] | null = null;

  for (const direction in directions) {
    for (const project of directions[direction]) {
      if (!result) {
        result = project.budgets;
        continue;
      }

      for (const budgetIndex in result) {
        result[budgetIndex].factDevelopment += project.budgets[budgetIndex].factDevelopment;
        result[budgetIndex].factCurrent += project.budgets[budgetIndex].factCurrent;
        result[budgetIndex].fact += project.budgets[budgetIndex].fact;
        result[budgetIndex].planDevelopment += project.budgets[budgetIndex].planDevelopment;
        result[budgetIndex].planCurrent += project.budgets[budgetIndex].planCurrent;
        result[budgetIndex].plan += project.budgets[budgetIndex].plan;
        result[budgetIndex].commitment += project.budgets[budgetIndex].commitment;
      }
    }
  }

  return result as IBudget[];
}

export function matchString(str: string, query: string) {
  return str.toLowerCase().indexOf(query.toLowerCase()) > -1;
}

export function searchModel(model: any, fields: string[], query: string) {
  for (const currentField of fields) {
    if (matchString(model[currentField], query)) {
      return true;
    }
  }

  return false;
}

/**
 * Функция проверки проекта на соответствие поисковому запросу
 * @param project
 * @param query
 */
export function isValidProject(project: IProject, query: string) {
  if (
    searchModel(project, ['name', 'description', 'productName', 'state', 'phase'], query) ||
    searchModel(project.block, ['name'], query) ||
    searchModel(project.direction, ['name'], query)
  ) {
    return true;
  }

  return !!(project.technologies.length && project.technologies.find((technology) => matchString(technology, query)));
}

export function makeDirectionName(directionOrder: string, directionName: string) {
  return directionOrder + ' ' + directionName;
}

export function directionSort(directionA: string, directionB: string) {
  const getDirectionOrder = (directionName: string) => {
    return directionName.split(' ')[0].split('.')[1];
  };

  const directionAOrder = Number(getDirectionOrder(directionA));
  const directionBOrder = Number(getDirectionOrder(directionB));

  return directionAOrder - directionBOrder;
}
