import { IUseAxiosQueryOptions, useAxiosQuery } from '@proscom/prostore-axios-react/lib/es/useAxiosQuery';
import { IBaseCatalog } from '../interfaces/ICatalog';

export interface CatalogsQueryVars {}

const queryOptions: IUseAxiosQueryOptions<CatalogsQueryVars | null, IBaseCatalog[]> = {
  query: {
    url: '/api/catalogs',
    method: 'get'
  }
};

export function useCatalogs(variables: CatalogsQueryVars = {}) {
  return useAxiosQuery({
    queryOptions,
    variables
  });
}
