import clsx from 'clsx';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useStoreState } from '@proscom/prostore-react';
import { ActivityLogger } from '../../common/components/utils/ActivityLogger';
import { AuthStoreState, Role } from '../../store/AuthStore';
import { STORE_AUTH } from '../../store/stores';
import { ICatalogData, TopBar } from './TopBar/TopBar';
import s from './UserLayout.module.scss';

export interface UserLayoutConfig {
  admin?: boolean;
  fullScreen?: boolean;
}

export function createWithUserLayout(config?: UserLayoutConfig) {
  return function withUserLayout<Props>(Comp: React.ComponentType<Props>) {
    return function (props: Props) {
      const [catalogData, setCatalogData] = useState<ICatalogData | undefined>();

      const authStoreState = useStoreState<AuthStoreState>(STORE_AUTH);

      if (!authStoreState.authData) return <Redirect to={'/login'} />;
      if (config?.admin && authStoreState.authData.user.role !== Role.Admin) return <Redirect to={'/'} />;
      return (
        <div className={clsx(s.Wrapper, { [s.Wrapper_fullScreen]: config?.fullScreen })}>
          <TopBar catalogData={catalogData} isNavbarVisible={authStoreState.authData.user.role === Role.Admin} />
          <Comp onCatalogLoaded={setCatalogData} {...props} />
          <ActivityLogger />
        </div>
      );
    };
  };
}
