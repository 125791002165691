import React from 'react';
import clsx from 'clsx';
import s from './Toggle.module.scss';

export interface ToggleProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  onChange?: (checked: boolean) => void;
  outlined?: boolean;
}

export function Toggle({ checked, children, onChange, outlined, ...props }: ToggleProps) {
  return (
    <label
      className={clsx(s.Toggle, {
        [s.Toggle_outlined]: outlined
      })}
    >
      <input
        type="checkbox"
        className={s.Toggle__checkbox}
        checked={checked}
        onChange={(e) => onChange?.(e.target.checked)}
        {...props}
      />
      <div className={s.Toggle__switcher} />
      <div className={s.Toggle__label}>{children}</div>
    </label>
  );
}
