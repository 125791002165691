import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useStoreState } from '@proscom/prostore-react';
import { useMediaQueryResult } from '../../common/hooks/useMediaQueryResult';
import { STORE_AUTH } from '../../store/stores';
import { AuthStoreState } from '../../store/AuthStore';
import logo from '../../assets/logo.png';
import { SignInForm } from './SignInForm/SignInForm';
import backgroundLogo from './background-logo.svg';
import s from './SignInPage.module.scss';

export default function SignInPage() {
  const authStoreState = useStoreState<AuthStoreState>(STORE_AUTH);
  const tabletLarge = useMediaQueryResult('(min-width: 1194px)');

  useEffect(() => {
    document.body.style.backgroundColor = '#fff';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  if (authStoreState.authData) return <Redirect to={'/'} />;

  return (
    <React.Fragment>
      {tabletLarge && (
        <div className={s.SignInPage__background}>
          <img className={s.SignInPage__backgroundLogo} src={backgroundLogo} alt={''} />
        </div>
      )}

      <div className={s.SignInPage}>
        <Link to={'/'}>
          <img className={s.SignInPage__logo} src={logo} alt={'Правительство Москвы'} />
        </Link>

        <SignInForm className={s.SignInPage__signInForm} />

        <div className={s.SignInPage__supportNote}>
          Если вы забыли пароль, то вы можете связаться со{' '}
          <Link to={(location) => ({ ...location, search: 'feedbackForm' })}>службой поддержки.</Link>
        </div>
      </div>
    </React.Fragment>
  );
}
