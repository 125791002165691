import React from 'react';
import DeleteCatalogImage from '../../../assets/images/delete-catalog.svg';
import { Modal, ModalProps } from '../../../common/components/ui/Modal/Modal';
import { Button, ButtonColor, ButtonVariant } from '../../../common/components/ui/Button/Button';
import { useCatalogRemove } from '../../../store/mutation/useCatalogRemove';
import s from './DeleteModal.module.scss';

export interface DeleteCatalogModalProps extends ModalProps {
  catalogId: number;
  onApprove: () => void;
}

export function DeleteCatalogModal({ catalogId, onApprove, ...props }: DeleteCatalogModalProps) {
  const deleteCatalogRequest = useCatalogRemove();

  function handleOnApproveClick() {
    deleteCatalogRequest.run(catalogId).then((response) => {
      onApprove?.();
      props.onClose?.();
    });
  }

  return (
    <Modal {...props}>
      <div className={s.DeleteModal}>
        <img src={DeleteCatalogImage} className={s.DeleteModal__image} alt="Удаление каталога" />
        <div className={s.DeleteModal__header}>Вы хотите удалить каталог?</div>
        <div className={s.DeleteModal__description}>Каталог будет безвозвратно удален</div>

        <div className={s.DeleteModal__actions}>
          <Button
            className={s.DeleteModal__approveAction}
            variant={ButtonVariant.primary}
            color={ButtonColor.primary}
            onClick={handleOnApproveClick}
          >
            Удалить
          </Button>
          <Button
            className={s.DeleteModal__rejectAction}
            onClick={props?.onClose}
            variant={ButtonVariant.secondary}
            color={ButtonColor.secondary}
          >
            Отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
}
