import React, { CSSProperties, useMemo } from 'react';
import { ProjectCard } from '../../common/components/ui/Card/ProjectCard';
import { BudgetTable } from '../../common/components/ui/Table/BudgetTable';
import { IProject } from '../../store/interfaces/IProject';
import { IBudget } from '../../store/interfaces/IBudget';
import { computeTotalBudget, directionSort, isValidProject, makeDirectionName } from '../../utils/catalog';
import { Spoiler } from '../../common/components/ui/Spoiler/Spoiler';
import { getBlockColor as getDeputyColor } from '../../utils/blockColors';
import { useQuery } from '../../common/hooks/useQuery';
import { NothingFound } from '../../common/components/ui/NothingFound/NothingFound';
import { DirectionsPageProps } from './DirectionsPage';
import s from './CatalogPage.module.scss';

export interface ProjectsPageProps extends DirectionsPageProps {
  projectsList?: IProject[] | null;
}

export interface IGroupedProjects {
  [responsibleOfficerName: string]: {
    id: number;
    totalBudget: IBudget[];
    directions: {
      [directionName: string]: IProject[];
    };
  };
}

export function ProjectsPage({ deputiesList, directionsList, projectsList, showBudget }: ProjectsPageProps) {
  const [query, changeQuery] = useQuery();
  const searchQuery = query?.q;

  const groupedProjects: IGroupedProjects = useMemo(() => {
    const result: IGroupedProjects = {};

    if (!projectsList || !directionsList || !deputiesList) {
      return {};
    }

    projectsList?.forEach((project) => {
      if (searchQuery && !isValidProject(project, searchQuery)) {
        return;
      }

      const deputyName = project.block.name;

      if (!result[deputyName]) {
        result[deputyName] = {
          id: project.block.id,
          totalBudget: [],
          directions: {}
        };
      }

      const directionName = makeDirectionName(project.direction.order, project.direction.name);

      if (!result[deputyName].directions[directionName]) {
        result[deputyName].directions[directionName] = [];
      }

      result[deputyName].directions[directionName].push(project);
    });

    for (const deputyName in result) {
      result[deputyName].totalBudget = computeTotalBudget(result[deputyName].directions);
    }

    return result;
  }, [deputiesList, directionsList, projectsList, searchQuery]);

  return (
    <>
      {projectsList?.length === 0 && <NothingFound label="Нет данных" />}
      {Object.keys(groupedProjects).map((deputy, deputyIndex) => (
        <Spoiler
          key={deputyIndex}
          header={deputy}
          style={
            {
              '--accentColor': getDeputyColor(groupedProjects[deputy].id)
            } as CSSProperties
          }
        >
          {!searchQuery && showBudget && <BudgetTable data={groupedProjects[deputy].totalBudget} />}
          <div className={s.CatalogPage__projectsInnerSpoilers}>
            {Object.keys(groupedProjects[deputy].directions)
              .sort(directionSort)
              .map((direction, directionIndex) => (
                <Spoiler
                  key={directionIndex}
                  header={direction}
                  classNames={{ header: s.CatalogPage__projectsSpoilerHeader }}
                >
                  <div className={s.CatalogPage__cards}>
                    {groupedProjects[deputy].directions[direction]?.map((project) => (
                      <ProjectCard key={project.id} data={project} />
                    ))}
                  </div>
                </Spoiler>
              ))}
          </div>
        </Spoiler>
      ))}
      {searchQuery && Object.keys(groupedProjects).length === 0 && <NothingFound label="Ничего не найдено" />}
    </>
  );
}
