import React, { useRef } from 'react';
import { useClickOutside } from '@proscom/ui-react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import s from './Modal.module.scss';

export interface ModalProps extends React.HTMLProps<HTMLDivElement> {
  onClose: () => void;
  width?: number;
}

export function Modal({ onClose, width = 400, children, ...props }: ModalProps) {
  const modalContentRef = useRef(null);

  useClickOutside(modalContentRef, onClose);

  return (
    <div className={s.Modal} {...props}>
      <div className={s.Modal__container}>
        <div className={s.Modal__content} style={{ width }} ref={modalContentRef}>
          {children}
        </div>
        <button onClick={onClose} className={s.Modal__close}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}
