import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { EMDASH } from '@proscom/ui-utils';
import AppRoutes from './routes';

const siteTitle = 'Цифровые проекты Москвы';

export function App() {
  return (
    <Fragment>
      <Helmet defaultTitle={siteTitle} titleTemplate={`%s ${EMDASH} ${siteTitle}`} />

      <AppRoutes />
    </Fragment>
  );
}
