import React from 'react';
import clsx from 'clsx';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { Search } from '../../../common/components/ui/Input/Search';
import { useQuery } from '../../../common/hooks/useQuery';
import { Button, ButtonColor } from '../../../common/components/ui/Button/Button';
import { TSort } from '../UsersPage';
import { Box } from '../../../common/components/ui/Box/Box';
import s from './UsersFilter.module.scss';

export function UsersFilter() {
  const [query, changeQuery] = useQuery();
  const sort: TSort = query?.sort === 'desc' ? 'desc' : 'asc';

  const handleQueryChange = (value: string) => {
    changeQuery({
      q: value
    });
  };

  const handleAddUserClick = () => {
    changeQuery({
      action: 'user-add'
    });
  };

  const handleSortClick = () => {
    changeQuery({
      sort: sort === 'asc' ? 'desc' : 'asc'
    });
  };

  return (
    <Box>
      <div className={s.UsersFilter}>
        <form action="" method="get" className={s.UsersFilter__search}>
          <Search value={query?.q} onChange={handleQueryChange} placeholder="Поиск по сотрудникам" />
        </form>
        <div className={s.UsersFilter__sort}>
          <button
            type="button"
            className={clsx(s.UsersFilter__sortButton, {
              [s.UsersFilter__sortButton_desc]: sort === 'desc'
            })}
            onClick={handleSortClick}
          >
            <SortIcon className={s.UsersFilter__sortButtonIcon} />
          </button>
        </div>
        <div className={s.UsersFilter__addUser}>
          <Button onClick={handleAddUserClick} color={ButtonColor.primary} leftIcon={<PlusIcon />}>
            Добавить пользователя
          </Button>
        </div>
      </div>
    </Box>
  );
}
