import React from 'react';
import ReactSelect from 'react-select';

export interface SelectOption {
  label: string;
  value: any;
}

export interface SelectProps {
  options: SelectOption[];
  value?: SelectOption;
  onChange?: (option?: SelectOption) => void;
  name?: string;
  ref?: any;
}

export function Select({ value, options, onChange, ...props }: SelectProps) {
  return (
    <ReactSelect
      name={props.name}
      value={value}
      options={options}
      onChange={(value) => {
        onChange?.(value ? value : undefined);
      }}
      placeholder="Выберите"
      autocomplete="off"
      styles={{
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none'
        }),
        control: (provided, state) => ({
          ...provided,
          backgroundColor: '#ffffff',
          border: `1px solid #EAEAEB`,
          padding: '6px 0',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 10,
          ...(state.isFocused
            ? {
                borderColor: '#065BFF'
              }
            : {})
        }),
        option: (provided, state) => ({
          margin: '8px 8px',
          padding: '10px 16px',
          borderRadius: '10px',
          color: '#2B2C34',

          ':hover': {
            backgroundColor: 'rgba(43, 44, 52, 0.05);',
            ...(state.isSelected
              ? {
                  backgroundColor: '#065BFF',
                  color: '#FFFFFF'
                }
              : {})
          },
          ...(state.isSelected
            ? {
                backgroundColor: '#065BFF',
                color: '#FFFFFF'
              }
            : {})
        }),
        menu: (provided, state) => ({
          ...provided,
          borderRadius: '10px',
          border: '1px solid #EAEAEB',
          boxShadow: 'none'
        })
      }}
      {...props}
    />
  );
}
