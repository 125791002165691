import { IUseAxiosQueryOptions, useAxiosQuery } from '@proscom/prostore-axios-react/lib/es/useAxiosQuery';
import { IUser } from '../interfaces/IUser';

export interface UsersQueryVars {}

const queryOptions: IUseAxiosQueryOptions<UsersQueryVars | null, IUser[]> = {
  query: {
    url: '/api/users',
    method: 'get'
  }
};

export function useUsers(variables: UsersQueryVars = {}) {
  return useAxiosQuery({
    queryOptions,
    variables
  });
}
