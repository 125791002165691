import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { difference } from 'lodash-es';
import { Select, SelectOption } from '../../../common/components/ui/Input/Select';
import { FormTextInput } from '../../../common/components/ui/Form/FormTextInput';
import { Form } from '../../../common/components/ui/Form/Form';
import { FormToggle } from '../../../common/components/ui/Form/FormToggle';
import { FormSelect } from '../../../common/components/ui/Form/FormSelect';
import { ModalBlock } from '../../../common/components/ui/Modal/ModalBlock';
import { ModalGroup } from '../../../common/components/ui/Modal/ModalGroup';
import { IUser } from '../../../store/interfaces/IUser';
import { useCatalogs } from '../../../store/query/useCatalogs';
import { useDirections } from '../../../store/query/useDirections';
import { useDeputies } from '../../../store/query/useDeputies';
import { TRole } from '../table/UserRow';
import { IBaseCatalog, ICatalog } from '../../../store/interfaces/ICatalog';
import { useResponseSelectData } from '../../../common/hooks/useResponseSelectData';
import { useAllProjects } from '../../../store/query/useAllProjects';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { Button, ButtonColor, ButtonVariant } from '../../../common/components/ui/Button/Button';
import { Checkbox } from '../../../common/components/ui/Checkbox/Checkbox';
import { useAvailableSelectDataForAccess } from '../../../common/hooks/useAvailableSelectDataForAccess';
import { CheckboxSelect } from '../../../common/components/ui/Input/CheckboxSelect';
import { IAccessToCatalogsState, useAccessData } from '../../../common/hooks/useAccessData';
import s from './UserModal.module.scss';

export interface UserDataFormProps {
  data?: IUser;
  onSubmit?: (formData: TBackendUserFormData) => void;
  formSubmitRef?: React.RefObject<HTMLInputElement>;
}

const USER_ROLES: SelectOption[] = [
  {
    label: 'Пользователь',
    value: 'USER'
  },
  {
    label: 'Администратор',
    value: 'ADMIN'
  }
];

export type TUserFormData = {
  name: string;
  email: string;
  password: string;
  finances_access: boolean;
  role: SelectOption;
  catalogs: SelectOption[];
  deputies: SelectOption[];
  directions: SelectOption[];
  projects: SelectOption[];
};

export type TBackendUserFormData = {
  name: string;
  email: string;
  password: string;
  finances_access: boolean;
  role: TRole;
  catalogs: number[];
  deputies: number[];
  directions: number[];
  projects: number[];
};

export interface IAccessToCatalogs {
  catalogs: number[];
  deputies: number[];
  directions: number[];
  projects: number[];
}

const userValidationSchema = yup.object({
  name: yup.string().required('Обязательное поле'),
  email: yup
    .string()
    .matches(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      'Неправильный адрес электронной почты'
    )
    .required('Обязательное поле'),
  password: yup.string().required('Обязательное поле')
});

const userUpdateValidationSchema = yup.object({
  name: yup.string().required('Обязательное поле'),
  email: yup
    .string()
    .matches(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      'Неправильный адрес электронной почты'
    )
    .required('Обязательное поле'),
  password: yup.string()
});

export function UserDataForm({ data, onSubmit, formSubmitRef }: UserDataFormProps) {
  const [userRole, setUserRole] = useState<SelectOption>(USER_ROLES[0]);

  const [accessData, setAccessData] = useAccessData(data);

  const catalogsQuery = useCatalogs();
  const catalogsData = catalogsQuery.state.data;

  const deputiesQuery = useDeputies({});
  const deputiesData = deputiesQuery.state.data || [];

  const directionsQuery = useDirections({});
  const directionsData = directionsQuery.state.data || [];

  const projectsQuery = useAllProjects({});
  const projectsData = projectsQuery.state.data || [];

  const catalogsSelectData = useResponseSelectData<IBaseCatalog>(catalogsData);

  const availableAccessData = useAvailableSelectDataForAccess(accessData, deputiesData, directionsData, projectsData);

  const handleFormSubmit = (data: TUserFormData) => {
    const { name, password, email, finances_access, role, ...otherData } = data;

    const transformedData: TBackendUserFormData = {
      name,
      password,
      email,
      role: role.value,
      catalogs: accessData.filter((access) => access.isFullAccess).map((access) => access.catalogId),
      deputies: accessData.flatMap((access) => access.deputies),
      directions: accessData.flatMap((access) => access.directions),
      projects: accessData.flatMap((access) => access.projects),
      finances_access
    };

    const ignoredDeputies = new Set(),
      ignoredDirections = new Set();

    const directions = transformedData.directions.map((directionId) =>
      directionsData.find((d) => d.id === directionId)
    );
    const projects = transformedData.projects.map((projectId) => projectsData.find((p) => p.id === projectId));

    directions.forEach((direction) => ignoredDeputies.add(direction?.block.id));
    projects.forEach((project) => {
      ignoredDeputies.add(project?.block.id);
      ignoredDirections.add(project?.direction.id);
    });

    transformedData.deputies = difference(transformedData.deputies, Array.from(ignoredDeputies) as number[]);
    transformedData.directions = difference(transformedData.directions, Array.from(ignoredDirections) as number[]);

    onSubmit?.(transformedData);
  };

  const handleRoleSelectChange = useCallback(
    (value: any) => {
      setUserRole(value);
    },
    [setUserRole]
  );

  const handleAccessDataChange = useCallback(
    (catalogId: number, field: keyof IAccessToCatalogsState, value: any) => {
      const accessDataCopy: IAccessToCatalogsState[] = JSON.parse(JSON.stringify(accessData));

      const catalog = accessDataCopy.find((access) => access.catalogId === catalogId);
      if (catalog && field in catalog) {
        // @ts-ignore
        catalog[field] = value;
      }

      if (catalog && field === 'isFullAccess' && value) {
        catalog.projects = [];
        catalog.deputies = [];
        catalog.directions = [];
      }

      setAccessData(accessDataCopy);
    },
    [accessData, setAccessData]
  );

  const handleDeleteCatalog = useCallback(
    (accessId: any) => {
      const accessDataCopy = JSON.parse(JSON.stringify(accessData));
      accessDataCopy.splice(accessId, 1);
      setAccessData(accessDataCopy);
    },
    [accessData, setAccessData]
  );

  useEffect(() => {
    if (!data) {
      setUserRole(USER_ROLES[0]);
    } else {
      setUserRole(data?.role === 'ADMIN' ? USER_ROLES[1] : USER_ROLES[0]);
    }
  }, [data]);

  return (
    <Form
      onSubmit={handleFormSubmit}
      formSubmitRef={formSubmitRef}
      validationSchema={data ? userUpdateValidationSchema : userValidationSchema}
      autoComplete="off"
    >
      <ModalGroup header="Личные данные">
        <ModalBlock header="ФИО">
          <FormTextInput placeholder="Фамилия Имя Отчество" name="name" defaultValue={data?.name || ''} />
        </ModalBlock>
        <ModalBlock header="Почта">
          <FormTextInput placeholder="Почта" name="email" defaultValue={data?.email || ''} />
        </ModalBlock>
        <ModalBlock header="Пароль">
          <FormTextInput
            type="password"
            placeholder="Введите новый пароль"
            name="password"
            defaultValue={data?.password || ''}
            autoComplete="new-password"
          />
        </ModalBlock>
        <ModalBlock header="Роль">
          <FormSelect
            name="role"
            options={USER_ROLES}
            defaultValue={data?.role === 'ADMIN' ? USER_ROLES[1] : USER_ROLES[0]}
            onChange={handleRoleSelectChange}
          />
        </ModalBlock>
        {userRole.value === USER_ROLES[0].value && (
          <ModalBlock header="Финансы">
            <FormToggle outlined name="finances_access" defaultValue={data?.accessToFinance ? 1 : 0}>
              Отображать финансы
            </FormToggle>
          </ModalBlock>
        )}
      </ModalGroup>

      {userRole.value === USER_ROLES[0].value &&
        accessData.map((access, accessIndex) => {
          const availableData = availableAccessData[access.catalogId];

          return (
            <ModalGroup
              key={accessIndex}
              header={`Доступ к каталогу ${accessIndex + 1}`}
              actions={
                <Button
                  leftIcon={<DeleteIcon />}
                  variant={ButtonVariant.tertiary}
                  color={ButtonColor.secondary}
                  className={s.UserModal__deleteAccess}
                  onClick={() => handleDeleteCatalog(accessIndex)}
                >
                  Очистить
                </Button>
              }
            >
              <ModalBlock header="Каталог">
                <Select
                  name="catalog"
                  options={catalogsSelectData}
                  value={
                    access.catalogId ? catalogsSelectData.find((item) => item.value === access.catalogId) : undefined
                  }
                  onChange={(value) => handleAccessDataChange(access.catalogId, 'catalogId', value?.value)}
                />
              </ModalBlock>

              {access.catalogId ? (
                <Checkbox
                  checked={access.isFullAccess}
                  disabled={access.catalogId === 0}
                  onChange={() => handleAccessDataChange(access.catalogId, 'isFullAccess', !access.isFullAccess)}
                >
                  Полный доступ
                </Checkbox>
              ) : null}

              {availableAccessData[access.catalogId]?.deputies && (
                <ModalBlock header="Замы">
                  <CheckboxSelect
                    name="deputies"
                    options={availableData.deputies || []}
                    value={availableData.selectedDeputies || []}
                    onChange={(options) => {
                      handleAccessDataChange(
                        access.catalogId,
                        'deputies',
                        options.map((value) => value.value)
                      );
                    }}
                  />
                </ModalBlock>
              )}

              {availableAccessData[access.catalogId]?.directions && (
                <ModalBlock header="Направления">
                  <CheckboxSelect
                    name="directions"
                    options={availableData.directions || []}
                    value={availableData.selectedDirections || []}
                    onChange={(options) => {
                      handleAccessDataChange(
                        access.catalogId,
                        'directions',
                        options.map((value) => value.value)
                      );
                    }}
                  />
                </ModalBlock>
              )}

              {availableAccessData[access.catalogId]?.projects && (
                <ModalBlock header="Проекты">
                  <CheckboxSelect
                    name="projects"
                    options={availableData.projects || []}
                    value={availableData.selectedProjects || []}
                    onChange={(options) => {
                      handleAccessDataChange(
                        access.catalogId,
                        'projects',
                        options.map((value) => value.value)
                      );
                    }}
                  />
                </ModalBlock>
              )}
            </ModalGroup>
          );
        })}

      <ModalGroup>
        <div className={s.UserModal__submitAddAction}>
          {userRole.value === USER_ROLES[0].value && (
            <Button
              variant={ButtonVariant.secondary}
              color={ButtonColor.secondary}
              leftIcon={<PlusIcon />}
              type="button"
              onClick={() => {
                const accessDataCopy: IAccessToCatalogsState[] = JSON.parse(JSON.stringify(accessData));
                accessDataCopy.push({
                  catalogId: 0,
                  isFullAccess: true,
                  deputies: [],
                  directions: [],
                  projects: []
                });
                setAccessData(accessDataCopy);
              }}
            >
              Добавить каталог
            </Button>
          )}
          {!data && (
            <Button variant={ButtonVariant.primary} color={ButtonColor.secondary} type="submit">
              Добавить пользователя
            </Button>
          )}
        </div>
      </ModalGroup>
    </Form>
  );
}
