import { IUseAxiosQueryOptions, useAxiosQuery } from '@proscom/prostore-axios-react/lib/es/useAxiosQuery';
import { IDirection } from '../interfaces/IDirection';

export interface DirectionsQueryVars {
  catalogId?: number;
}

const queryOptions: IUseAxiosQueryOptions<DirectionsQueryVars | null, IDirection[]> = {
  query: {
    url: `/api/directions`,
    method: 'get'
  },
  mapVariables: (variables) => ({
    params: {
      ...variables
    }
  })
};

export function useDirections(variables: DirectionsQueryVars) {
  return useAxiosQuery({
    queryOptions,
    variables
  });
}
