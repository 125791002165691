import React from 'react';
import * as yup from 'yup';
import { Form } from '../../../common/components/ui/Form/Form';
import { ModalGroup } from '../../../common/components/ui/Modal/ModalGroup';
import { ModalBlock } from '../../../common/components/ui/Modal/ModalBlock';
import { FormTextInput } from '../../../common/components/ui/Form/FormTextInput';
import { FormToggle } from '../../../common/components/ui/Form/FormToggle';
import { FormDateInput } from '../../../common/components/ui/Form/FormDateInput';

export type TCatalogFormData = {
  title: string;
  short_description: string;
  date: Date;
  is_actual: boolean;
};

export interface CatalogDataFormProps {
  data?: TCatalogFormData;
  onSubmit?: (formData: TCatalogFormData) => void;
  formSubmitRef?: React.RefObject<HTMLInputElement>;
}

const catalogValidationSchema = yup.object({
  title: yup.string().required('Обязательное поле'),
  short_description: yup.string().required('Обязательное поле'),
  date: yup.date().required('Обязательное поле')
});

export function CatalogDataForm({ data, onSubmit, formSubmitRef }: CatalogDataFormProps) {
  const handleFormSubmit = (data: TCatalogFormData) => {
    onSubmit?.(data);
  };

  return (
    <Form onSubmit={handleFormSubmit} formSubmitRef={formSubmitRef} validationSchema={catalogValidationSchema}>
      <ModalGroup>
        <ModalBlock header="Имя каталога">
          <FormTextInput placeholder="Название каталога" name="title" defaultValue={data?.title} />
        </ModalBlock>
        <ModalBlock header="Краткое описание">
          <FormTextInput
            placeholder="Краткое описание каталога"
            name="short_description"
            defaultValue={data?.short_description}
          />
        </ModalBlock>
        <ModalBlock header="Дата создания">
          <FormDateInput name="date" defaultValue={data ? new Date(data.date) : new Date()} />
        </ModalBlock>
        <ModalBlock header="">
          <FormToggle name="is_actual" defaultValue={Number(data?.is_actual ? 1 : 0)}>
            Бейдж "Актуальный"
          </FormToggle>
        </ModalBlock>
      </ModalGroup>
    </Form>
  );
}
