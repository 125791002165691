import React from 'react';
import { Link } from 'react-router-dom';
import { createUrlPreserver } from '@proscom/prostore-react-router';
import { IProject } from '../../../../store/interfaces/IProject';
import { useQuery } from '../../../hooks/useQuery';
import s from './ProjectCard.module.scss';

export interface ProjectCardProps {
  data: IProject;
}

export function ProjectCard({ data }: ProjectCardProps) {
  const [query, changeQuery] = useQuery();
  const urlPreserver = createUrlPreserver(query || {});

  return (
    <Link to={urlPreserver(`/catalog/${data.catalog.id}/project/${data.id}`)} className={s.ProjectCard}>
      <div className={s.ProjectCard__title}>{data.name}</div>
      {data.state === 'Новый проект' && <div className={s.ProjectCard__newLabel}>Новый</div>}
    </Link>
  );
}
