import { FieldErrors } from 'react-hook-form/dist/types/errors';
import React, { ReactNode } from 'react';
import { DeepPartial, FieldValues, FormProvider, UnpackNestedValue, useForm, UseFormMethods } from 'react-hook-form';
import { useYupValidationResolver } from '../../../hooks/useYupValidationResolver';
import s from './FormHiddenInput.module.scss';

export interface FormControlProps<TShape extends FieldValues> {
  onSubmit: (data: TShape, formMethods?: UseFormMethods<TShape>) => void;
  defaultData?: UnpackNestedValue<DeepPartial<TShape>>;
  validationSchema?: any;
  children: ((data: UnpackNestedValue<TShape>, errors: FieldErrors<TShape>) => ReactNode) | ReactNode;
  formSubmitRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  autoComplete?: string;
}

export function Form<T extends FieldValues>({
  onSubmit,
  defaultData,
  validationSchema,
  children,
  formSubmitRef,
  autoComplete,
  className
}: FormControlProps<T>) {
  const resolver = useYupValidationResolver(validationSchema);

  const methods = useForm<T>({
    resolver,
    defaultValues: defaultData
  });
  const { watch, errors } = methods;
  const data = watch();

  function handleSubmit(data: any) {
    onSubmit?.(data, methods);
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className={className} autoComplete={autoComplete}>
        {typeof children === 'function' ? children(data, errors) : children}
        <input type="submit" ref={formSubmitRef} className={s.FormHiddenInput} />
      </form>
    </FormProvider>
  );
}
