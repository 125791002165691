import clsx from 'clsx';
import React from 'react';
import { ReactComponent } from './loading.svg';
import s from './Loader.module.scss';

export interface LoaderProps {
  inline?: boolean;
  className?: string;
}

export function Loader(props: LoaderProps) {
  return <ReactComponent className={clsx(props.inline ? s.LoaderInline : s.Loader, props.className)} />;
}
