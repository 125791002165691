import React from 'react';
import clsx from 'clsx';
import { IBudget } from '../../../../store/interfaces/IBudget';
import { getBudgetYears } from '../../../../utils/catalog';
import {
  getBudgetInfo,
  getCommitmentInfo,
  getCurrentBudgetInfo,
  getDevelopmentBudgetInfo
} from '../../../../utils/budget';
import s from './BudgetTable.module.scss';

export interface BudgetTableProps {
  data: IBudget[];
  color?: string;
  isLabelRow?: boolean; //надпись располагается на отдельной строке
}

export function BudgetTable({ data, isLabelRow = false }: BudgetTableProps) {
  const sortedBudget = getBudgetYears(data);

  return (
    <table
      className={clsx(s.BudgetTable, {
        [s.BudgetTable_small]: isLabelRow
      })}
    >
      <thead className={s.BudgetTable__header}>
        <tr>
          {!isLabelRow && <th className={s.BudgetTable__headerCell}> </th>}
          {sortedBudget.map((budget) => {
            const [budgetLabel] = getBudgetInfo(budget);

            return (
              <th key={budget.year} className={s.BudgetTable__headerCell}>
                {budgetLabel}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={s.BudgetTable__body}>
        {isLabelRow && (
          <tr className={clsx(s.BudgetTable__row, s.BudgetTable__row_fake)}>
            <td colSpan={3} className={clsx(s.BudgetTable__cell, s.BudgetTable__label)}>
              Общий бюджет (млн)
            </td>
          </tr>
        )}
        <tr className={s.BudgetTable__row}>
          {!isLabelRow && <td className={clsx(s.BudgetTable__cell, s.BudgetTable__label)}>Общий бюджет (млн)</td>}
          {data.map((budget, budgetIndex) => {
            const [_, budgetValue] = getBudgetInfo(budget);

            return (
              <td key={budgetIndex} className={s.BudgetTable__cell}>
                {budgetValue}
              </td>
            );
          })}
        </tr>

        {isLabelRow && (
          <tr
            className={clsx(
              s.BudgetTable__row,
              s.BudgetTable__row_arrow,
              s.BudgetTable__row_inner,
              s.BudgetTable__row_fake
            )}
          >
            <td colSpan={3} className={clsx(s.BudgetTable__cell, s.BudgetTable__label)}>
              Развитие (млн)
            </td>
          </tr>
        )}
        <tr
          className={clsx(s.BudgetTable__row, s.BudgetTable__row_inner, {
            [s.BudgetTable__row_arrow]: !isLabelRow
          })}
        >
          {!isLabelRow && <td className={clsx(s.BudgetTable__cell, s.BudgetTable__label)}>Развитие (млн)</td>}
          {data.map((budget, budgetIndex) => {
            const [_, budgetValue] = getDevelopmentBudgetInfo(budget);

            return (
              <td key={budgetIndex} className={s.BudgetTable__cell}>
                {budgetValue}
              </td>
            );
          })}
        </tr>

        {isLabelRow && (
          <tr className={clsx(s.BudgetTable__row, s.BudgetTable__row_inner, s.BudgetTable__row_fake)}>
            <td colSpan={3} className={clsx(s.BudgetTable__cell, s.BudgetTable__label)}>
              Текущие (млн)
            </td>
          </tr>
        )}
        <tr className={clsx(s.BudgetTable__row, s.BudgetTable__row_inner)}>
          {!isLabelRow && <td className={clsx(s.BudgetTable__cell, s.BudgetTable__label)}>Текущие (млн)</td>}
          {data.map((budget, budgetIndex) => {
            const [_, budgetValue] = getCurrentBudgetInfo(budget);

            return (
              <td key={budgetIndex} className={s.BudgetTable__cell}>
                {budgetValue}
              </td>
            );
          })}
        </tr>

        {isLabelRow && (
          <tr className={clsx(s.BudgetTable__row, s.BudgetTable__row_fake)}>
            <td colSpan={3} className={clsx(s.BudgetTable__cell, s.BudgetTable__label)}>
              Обязательства (млн)
            </td>
          </tr>
        )}
        <tr className={s.BudgetTable__row}>
          {!isLabelRow && <td className={clsx(s.BudgetTable__cell, s.BudgetTable__label)}>Обязательства (млн)</td>}
          {data.map((budget, budgetIndex) => (
            <td key={budgetIndex} className={s.BudgetTable__cell}>
              {getCommitmentInfo(budget)}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
