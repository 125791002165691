import { IUseAxiosQueryOptions, useAxiosQuery } from '@proscom/prostore-axios-react/lib/es/useAxiosQuery';
import { IProject } from '../interfaces/IProject';

export interface AllProjectsQueryVars {
  catalogId?: number;
  blockId?: number;
  directionId?: number;
}

const queryOptions: IUseAxiosQueryOptions<AllProjectsQueryVars | null, IProject[]> = {
  query: {
    url: `/api/projects`,
    method: 'get'
  },
  mapVariables: (variables) => ({
    params: {
      ...variables
    }
  })
};

export function useAllProjects(variables: AllProjectsQueryVars) {
  return useAxiosQuery({
    queryOptions,
    variables
  });
}
