import { WebStorageValueStore } from '@proscom/prostore-local-storage';
import { TView } from '../common/components/ui/Card/CatalogCard';
import { LOCAL_STORAGE_KEY_VIEW } from './storageKeys';

export class CatalogViewStore extends WebStorageValueStore<TView> {
  constructor() {
    super(localStorage, LOCAL_STORAGE_KEY_VIEW, (value) => {
      return value === 'grid' ? 'grid' : 'list';
    });
  }

  toggleView() {
    if (this.state.value === 'list') {
      this.setValue('grid');
    } else {
      this.setValue('list');
    }
  }

  getValue(): TView {
    return this.state.value || 'list';
  }
}
