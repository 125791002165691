import clsx from 'clsx';
import React, { FormEventHandler, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore, useStoreState } from '@proscom/prostore-react';
import { usePreventBodyScroll } from '../../../common/hooks/usePreventBodyScroll';
import { SubmitButton } from '../../../common/components/ui/SubmitButton/SubmitButton';
import { Loader } from '../../../common/components/ui/Loader/Loader';
import { AuthStoreState } from '../../../store/AuthStore';
import { FeedbackStore } from '../../../store/FeedbackStore';
import { STORE_AUTH, STORE_FEEDBACK } from '../../../store/stores';
import s from './FeedbackForm.module.scss';

export function FeedbackForm() {
  const authStoreState = useStoreState<AuthStoreState>(STORE_AUTH);
  const [state, store] = useStore<FeedbackStore>(STORE_FEEDBACK);
  const [message, setMessage] = useState('');

  usePreventBodyScroll();
  useEffect(() => {
    document.getElementById('message')?.focus();
  }, []);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    if (message && !state.loading) store.sendFeedback(message);
  };

  if (state.success) {
    return (
      <div className={s.Overlay}>
        <div
          className={clsx(s.Success, {
            [s.Success_withData]: authStoreState.authData
          })}
        >
          <Link to={(location) => ({ ...location, search: '' })} className={'material-icons ' + s.IconClose}>
            {'clear'}
          </Link>

          <div className={'material-icons ' + s.Success__circle}>{'textsms'}</div>

          <h3 className={s.Success__heading}>Отправлено!</h3>
          <p className={s.Success__message}>Спасибо за обратную связь</p>

          <Link className={s.Success__close} to={(location) => ({ ...location, search: '' })}>
            Закрыть
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={s.Overlay}>
      <form onSubmit={handleSubmit} className={clsx(s.Form, { [s.Form_noData]: !authStoreState.authData })}>
        <Link className={'material-icons ' + s.IconClose} to={(location) => ({ ...location, search: '' })}>
          {'clear'}
        </Link>

        <h3 className={s.Form__heading}>Обратная связь</h3>

        <label className={s.Form__label} htmlFor={'message'}>
          Оставьте комментарий
        </label>
        <textarea
          id={'message'}
          name={'message'}
          disabled={state.loading}
          className={s.Form__textarea}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        {state.error && <p className={s.Form__error}>{state.error}</p>}

        <div className={s.Form__submitContainer}>
          {state.loading && <Loader inline />}

          <SubmitButton className={s.From__submit} disabled={!message || state.loading}>
            Отправить
          </SubmitButton>
        </div>
      </form>
    </div>
  );
}
