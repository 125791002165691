import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { IUser } from '../../store/interfaces/IUser';

export interface IAccessToCatalogsState {
  catalogId: number;
  isFullAccess: boolean;
  deputies: number[];
  directions: number[];
  projects: number[];
}

export function useAccessData(
  data?: IUser
): [IAccessToCatalogsState[], Dispatch<SetStateAction<IAccessToCatalogsState[]>>] {
  const modifyCatalogs = useCallback(
    (
      catalogs: IAccessToCatalogsState[],
      catalogId: number,
      fieldName: 'deputies' | 'directions' | 'projects',
      value: number
    ) => {
      let catalogIndex = catalogs.findIndex((catalog) => {
        return catalog?.catalogId === catalogId;
      });

      if (catalogIndex === -1) {
        catalogIndex =
          catalogs.push({
            catalogId,
            isFullAccess: false,
            deputies: [],
            directions: [],
            projects: []
          }) - 1;
      }

      if (catalogs[catalogIndex][fieldName].indexOf(value) === -1) {
        catalogs[catalogIndex][fieldName].push(value);
      }
    },
    []
  );

  const data2Access = (data?: IUser) => {
    if (!data) {
      return [];
    }

    const catalogs: IAccessToCatalogsState[] = [];

    data.accessibleCatalogs.forEach((fullAccessCatalog) => {
      catalogs.push({
        catalogId: fullAccessCatalog.id,
        isFullAccess: true,
        deputies: [],
        directions: [],
        projects: []
      });
    });

    data.accessibleBlocks.forEach((block) => {
      modifyCatalogs(catalogs, block.catalogId, 'deputies', block.id);
    });

    data.accessibleDirections.forEach((direction) => {
      modifyCatalogs(catalogs, direction.catalogId, 'directions', direction.id);
      modifyCatalogs(catalogs, direction.catalogId, 'deputies', direction?.blockId);
    });

    data.accessibleProjects.forEach((project) => {
      modifyCatalogs(catalogs, project.catalogId, 'projects', project.id);
      modifyCatalogs(catalogs, project.catalogId, 'directions', project.directionId);
      modifyCatalogs(catalogs, project.catalogId, 'deputies', project.blockId);
    });

    return catalogs;
  };

  const [accessDataState, setAccessData] = useState<IAccessToCatalogsState[]>(() => data2Access(data));

  useEffect(() => {
    setAccessData(data2Access(data));
  }, [data]);

  return [accessDataState, setAccessData];
}
