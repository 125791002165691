import React from 'react';
import { DirectionCard } from '../../common/components/ui/Card/DirectionCard';
import { IDirection } from '../../store/interfaces/IDirection';
import { NothingFound } from '../../common/components/ui/NothingFound/NothingFound';
import { DeputyPageProps } from './DeputyPage';
import s from './CatalogPage.module.scss';

export interface DirectionsPageProps extends DeputyPageProps {
  directionsList?: IDirection[] | null;
}

export function DirectionsPage({ deputiesList, directionsList, showBudget }: DirectionsPageProps) {
  return (
    <>
      {directionsList?.length === 0 && <NothingFound label="Нет данных" />}
      <div className={s.CatalogPage__cards}>
        {directionsList?.map((direction) => {
          const deputy = deputiesList?.find((deputy) => deputy.id === direction.blockId);

          return (
            <DirectionCard
              showBudget={showBudget}
              key={direction.id}
              data={direction}
              deputyName={deputy?.name || ''}
            />
          );
        })}
      </div>
    </>
  );
}
