import { useMemo } from 'react';
import { SelectOption } from '../components/ui/Input/Select';
import { IDeputy } from '../../store/interfaces/IDeputy';
import { IDirection } from '../../store/interfaces/IDirection';
import { IProject } from '../../store/interfaces/IProject';
import { IAccessToCatalogsState } from './useAccessData';

export interface IAvailableSelectDataForCatalog {
  selectedDeputies?: SelectOption[];
  deputies?: SelectOption[];
  selectedDirections?: SelectOption[];
  directions?: SelectOption[];
  selectedProjects?: SelectOption[];
  projects?: SelectOption[];
}

export interface IAvailableSelectDataForAccessResponse {
  [K: number]: IAvailableSelectDataForCatalog;
}

export function useAvailableSelectDataForAccess(
  data: IAccessToCatalogsState[],
  deputiesData: IDeputy[],
  directionsData: IDirection[],
  projectsData: IProject[]
): IAvailableSelectDataForAccessResponse {
  return useMemo(() => {
    const result: IAvailableSelectDataForAccessResponse = {};

    data.forEach((selectedCatalog) => {
      if (selectedCatalog.catalogId) {
        result[selectedCatalog.catalogId] = {};
      }
    });

    if (!deputiesData.length || !directionsData.length || !projectsData.length) {
      return {};
    }

    deputiesData.forEach((deputy) => {
      const catalogId = deputy.catalogId;
      const catalogData = data.find((d) => {
        return d?.catalogId === catalogId;
      });

      if (catalogId in result && !catalogData?.isFullAccess) {
        if (!result[catalogId].deputies) {
          result[catalogId].deputies = [];
          result[catalogId].selectedDeputies = [];
        }

        const deputyOption = {
          label: deputy.name,
          value: deputy.id
        };

        result[catalogId].deputies?.push(deputyOption);

        if (catalogData && result[catalogId] && catalogData.deputies.indexOf(deputy.id) > -1) {
          result[catalogId].selectedDeputies?.push(deputyOption);
        }
      }
    });

    directionsData.forEach((direction) => {
      const catalogId = direction.catalogId;
      const catalogData = data.find((d) => d?.catalogId === catalogId);

      if (
        catalogId in result &&
        !catalogData?.isFullAccess &&
        catalogData &&
        catalogData?.deputies?.indexOf(direction.block.id) > -1
      ) {
        if (!result[catalogId].directions) {
          result[catalogId].directions = [];
          result[catalogId].selectedDirections = [];
        }

        const directionOption = {
          label: direction.name,
          value: direction.id
        };

        result[catalogId].directions?.push(directionOption);

        if (catalogData && result[catalogId] && catalogData.directions.indexOf(direction.id) > -1) {
          result[catalogId].selectedDirections?.push(directionOption);
        }
      }
    });

    projectsData.forEach((project) => {
      const catalogId = project.catalog.id;
      const catalogData = data.find((d) => d?.catalogId === catalogId);

      if (
        catalogId in result &&
        !catalogData?.isFullAccess &&
        catalogData &&
        catalogData?.directions?.indexOf(project.direction.id) > -1
      ) {
        if (!result[catalogId].projects) {
          result[catalogId].projects = [];
          result[catalogId].selectedProjects = [];
        }

        const projectOption = {
          label: project.name,
          value: project.id
        };

        result[catalogId].projects?.push(projectOption);

        if (catalogData && result[catalogId] && catalogData.projects.indexOf(project.id) > -1) {
          result[catalogId].selectedProjects?.push(projectOption);
        }
      }
    });

    return result;
  }, [data, deputiesData, directionsData, projectsData]);
}
