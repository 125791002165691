import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as VerticalMenuIcon } from '../../../../assets/icons/vertical-menu.svg';
import { ReactComponent as FolderIcon } from '../../../../assets/icons/folder.svg';
import { CatalogCardDropdown } from '../Dropdown/CatalogCardDropdown';
import { IBaseCatalog } from '../../../../store/interfaces/ICatalog';
import s from './CatalogGridItem.module.scss';

export interface CatalogListItemProps extends IBaseCatalog {
  isContextMenuAvailable: boolean;
}

export function CatalogGridItem({
  id,
  name,
  description,
  folderName,
  isActual,
  isContextMenuAvailable
}: CatalogListItemProps) {
  return (
    <Link to={`/catalog/${id}`} className={s.CatalogGridItem}>
      <div className={s.CatalogGridItem__header}>
        <div className={s.CatalogGridItem__title}>{name}</div>
        <div className={s.CatalogGridItem__badges}>
          {isActual && <span className={s.CatalogGridItem__badgeActual}>Актуальный</span>}
        </div>
        {isContextMenuAvailable && (
          <div className={s.CatalogGridItem__menu}>
            <CatalogCardDropdown catalogId={id}>
              <VerticalMenuIcon />
            </CatalogCardDropdown>
          </div>
        )}
      </div>
      <div className={s.CatalogGridItem__description}>{description}</div>
      <div className={s.CatalogGridItem__verticalSeparator} />
      <div className={s.CatalogGridItem__folder}>
        <FolderIcon className={s.CatalogGridItem__folderIcon} />
        {folderName}
      </div>
    </Link>
  );
}
