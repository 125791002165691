import React, { useEffect, useRef, useState } from 'react';
import { UserPic } from '../../../common/components/ui/UserPic/UserPic';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg';
import { SidebarModal, SidebarModalProps } from '../../../common/components/ui/Modal/SidebarModal';
import { ModalHeader } from '../../../common/components/ui/Modal/ModalHeader';
import { Button, ButtonColor, ButtonVariant } from '../../../common/components/ui/Button/Button';
import { ModalBlock } from '../../../common/components/ui/Modal/ModalBlock';
import { TagsList } from '../../../common/components/ui/Tag/TagsList';
import { ModalGroup } from '../../../common/components/ui/Modal/ModalGroup';
import { IUser } from '../../../store/interfaces/IUser';
import { useCatalogs } from '../../../store/query/useCatalogs';
import { compressName } from '../../../utils/compressName';
import { useUserUpdate } from '../../../store/mutation/useUserUpdate';
import { FormErrorMessage } from '../../../common/components/ui/Form/FormErrorMessage';
import { useAccessData } from '../../../common/hooks/useAccessData';
import { DeleteUserModal } from './DeleteUserModal';
import { TBackendUserFormData, UserDataForm } from './UserDataForm';
import s from './UserModal.module.scss';

export interface UserModalProps extends Omit<SidebarModalProps, 'data'> {
  data: IUser;
  editableMode?: boolean;
  onDelete: () => void;
  onSubmit: () => void;
}

export function UserModal({ data, onClose, onDelete, onSubmit, editableMode = false }: UserModalProps) {
  const formSubmitRef = useRef<HTMLInputElement>(null);

  const [isEditable, setIsEditable] = useState(editableMode);
  const [isDelete, setIsDelete] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const catalogsQuery = useCatalogs();
  const catalogsData = catalogsQuery.state.data || [];

  const updateUserRequest = useUserUpdate();

  const [accessData] = useAccessData(data);

  function handleEditFormSubmit(formData: TBackendUserFormData) {
    updateUserRequest
      .run(data.id, {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: formData.role,
        accessToFinance: Boolean(formData.finances_access),
        accessibleCatalogIds: formData.catalogs,
        accessibleBlockIds: formData.deputies,
        accessibleDirectionIds: formData.directions,
        accessibleProjectIds: formData.projects
      })
      .then(() => {
        setIsEditable(false);
        onSubmit?.();
      })
      .catch((err) => {
        setErrorText(err.message);
      });
  }

  useEffect(() => {
    setErrorText(null);
  }, [isEditable, data]);

  function handleSaveClick() {
    if (formSubmitRef.current) {
      formSubmitRef.current.click();
    }
  }

  return (
    <SidebarModal onClose={onClose}>
      <ModalHeader
        header={data.name}
        beforeHeader={
          <UserPic radius={50} fontSize={32} name={data.name} color="#06B4FF" className={s.UserModal__picture} />
        }
      >
        {isEditable ? (
          <>
            <Button color={ButtonColor.secondary} leftIcon={<SaveIcon />} onClick={handleSaveClick}>
              Сохранить
            </Button>
            <Button color={ButtonColor.primary} variant={ButtonVariant.secondary} onClick={() => setIsEditable(false)}>
              Отменить
            </Button>
          </>
        ) : (
          <>
            <Button
              color={ButtonColor.secondary}
              variant={ButtonVariant.secondary}
              leftIcon={<EditIcon />}
              onClick={() => setIsEditable(true)}
            >
              Изменить
            </Button>
            <Button
              color={ButtonColor.primary}
              variant={ButtonVariant.secondary}
              leftIcon={<DeleteIcon />}
              onClick={() => setIsDelete(true)}
            >
              Удалить
            </Button>
          </>
        )}
      </ModalHeader>
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
      {isEditable ? (
        <UserDataForm formSubmitRef={formSubmitRef} onSubmit={handleEditFormSubmit} data={data} />
      ) : (
        <>
          <ModalGroup header="Личные данные">
            <ModalBlock header="Почта">{data.email}</ModalBlock>
            <ModalBlock header="Пароль">**************</ModalBlock>
          </ModalGroup>

          <ModalGroup header="Доступы">
            <ModalBlock header="Роль">{data.role === 'ADMIN' ? 'Администратор' : 'Пользователь'}</ModalBlock>
            {data.role === 'USER' && (
              <ModalBlock header="Финансы">{data.accessToFinance ? 'Есть доступ' : 'Нет доступа'}</ModalBlock>
            )}
          </ModalGroup>

          {data.role === 'USER' &&
            accessData.map((access) => {
              const catalogId = access.catalogId;
              const catalog = catalogsData.find((c) => c.id === catalogId);

              if (access.isFullAccess) {
                return (
                  <ModalGroup header={`Доступ к каталогу "${catalog?.name}"`}>
                    <ModalBlock header="Полный доступ">Есть</ModalBlock>
                  </ModalGroup>
                );
              }

              const deputies = data.accessibleBlocks.filter((block) => {
                return access.deputies.indexOf(block.id) > -1;
              });

              const directions = data.accessibleDirections.filter((direction) => {
                return access.directions.indexOf(direction.id) > -1;
              });

              const projects = data.accessibleProjects.filter((project) => {
                return access.projects.indexOf(project.id) > -1;
              });

              return (
                <ModalGroup header={`Доступ к каталогу "${catalog?.name}"`} key={catalogId}>
                  {deputies.length ? (
                    <ModalBlock header="Замы">
                      {<TagsList tags={deputies.map((deputy) => compressName(deputy.name))} />}
                    </ModalBlock>
                  ) : null}
                  {directions.length ? (
                    <ModalBlock header="Направления">
                      {<TagsList tags={directions?.map((direction) => direction.name)} />}
                    </ModalBlock>
                  ) : null}
                  {projects.length ? (
                    <ModalBlock header="Проекты">
                      {<TagsList tags={projects?.map((project) => project.name)} />}
                    </ModalBlock>
                  ) : null}
                </ModalGroup>
              );
            })}
        </>
      )}
      {isDelete && (
        <DeleteUserModal
          userId={data.id}
          onApprove={() => {
            onDelete?.();
            onClose?.();
          }}
          onClose={() => {
            setIsDelete(false);
          }}
        />
      )}
    </SidebarModal>
  );
}
