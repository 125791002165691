import React, { CSSProperties, useState } from 'react';
import { createUrlPreserver } from '@proscom/prostore-react-router';
import { Box } from '../../common/components/ui/Box/Box';
import { BackLink } from '../../common/components/ui/BackLink/BackLink';
import { Tag } from '../../common/components/ui/Tag/Tag';
import { Spoiler } from '../../common/components/ui/Spoiler/Spoiler';
import { DocumentCard } from '../../common/components/ui/Card/DocumentCard';
import { Slideshow } from '../../common/components/ui/Slideshow/Slideshow';
import { useProject } from '../../store/query/useProject';
import { queryLoader } from '../../common/components/utils/queryLoader';
import { BudgetTable } from '../../common/components/ui/Table/BudgetTable';
import { getBlockColor } from '../../utils/blockColors';
import { useQuery } from '../../common/hooks/useQuery';
import { useBudgetAccess } from '../../common/hooks/useBudgetAccess';
import s from './ProjectPage.module.scss';

export interface IProjectPageProps {
  match: { params: { projectId: string } };
}

export const SLIDES = [
  'https://aboveart.ru/wp-content/uploads/2020/12/International-Landscape-Photographer-Year-PhotographER-1st-KelvinYuen-2.jpg',
  'https://i.pinimg.com/originals/bd/6c/0b/bd6c0bef4a473bfca44d1f6c83c95006.png',
  'https://d3hnfqimznafg0.cloudfront.net/images/news/ImageForNews_26919_15786618897301054.png',
  'https://eco-business.imgix.net/uploads/ebmedia/fileuploads/shutterstock_158925020.jpg?fit=crop&h=960&ixlib=django-1.2.0&w=1440',
  'https://cdn.theculturetrip.com/wp-content/uploads/2017/11/yurt-486866_1280.jpg'
];

export default function ProjectPage({ match }: IProjectPageProps) {
  const [query, changeQuery] = useQuery();
  const urlPreserver = createUrlPreserver(query || {});

  const showBudget = useBudgetAccess();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const projectId = +match.params.projectId;
  const projectQuery = useProject(projectId);
  const projectData = projectQuery.state.data;

  return (
    <>
      {queryLoader(projectQuery) || (
        <Box
          className={s.ProjectPage}
          style={{ '--accentColor': getBlockColor(projectData?.block.id || 0) } as CSSProperties}
        >
          <BackLink to={urlPreserver('/catalog/' + projectData?.catalog.id)}>
            Каталоги проектов / {projectData?.catalog.name} / {projectData?.block.name} / {projectData?.direction.name}
          </BackLink>
          <div className={s.ProjectPage__information}>
            <div className={s.ProjectPage__title}>{projectData?.name}</div>

            {projectData?.link && (
              <a className={s.ProjectPage__link} href={projectData?.link} target="_blank" rel="noreferrer">
                Ссылка на сайт
              </a>
            )}

            <div className={s.ProjectPage__tagsList}>
              {projectData?.state && <Tag autoColored>{projectData.state}</Tag>}
              {projectData?.phase && <Tag autoColored>{projectData.phase}</Tag>}
              {projectData?.technologies.map((technology) => (
                <Tag>{technology}</Tag>
              ))}
            </div>

            <div className={s.ProjectPage__description}>
              {projectData?.description && (
                <Spoiler header="Описание проекта" classNames={{ content: s.ProjectPage__spoilerContent }} isOpen>
                  {projectData?.description.split('\n').map((str, idx) => (
                    <p key={`${str[0]}${idx}`}>{str}</p>
                  ))}
                </Spoiler>
              )}

              {showBudget && projectData?.budgets?.length ? (
                <Spoiler header="Бюджет" isOpen classNames={{ content: s.ProjectPage__spoilerContent }}>
                  {projectData && <BudgetTable isLabelRow={true} data={projectData?.budgets} />}
                </Spoiler>
              ) : null}

              {projectData?.files.length ? (
                <Spoiler header="Доп. материалы" classNames={{ content: s.ProjectPage__spoilerContent }} isOpen>
                  {projectData.files.map((file) => (
                    <DocumentCard key={file.id} file={file} />
                  ))}
                </Spoiler>
              ) : null}
            </div>
          </div>
          <div className={s.ProjectPage__presentation}>
            {projectData?.imageTokens?.length ? (
              <Slideshow
                slides={projectData?.imageTokens || []}
                fileId={projectData?.primaryFileId}
                currentSlideIndex={currentSlideIndex}
                onSlideChange={setCurrentSlideIndex}
                onFullClick={(slideIndex) => {
                  window.location.href = window.location.pathname + '/presentation?slide=' + slideIndex;
                }}
              />
            ) : null}
          </div>
        </Box>
      )}
    </>
  );
}
