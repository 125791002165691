import React from 'react';
import { Box } from '../../../common/components/ui/Box/Box';
import s from './TableLetter.module.scss';

export interface TableLetterProps {
  letter: string;
}

export function TableLetter({ letter }: TableLetterProps) {
  return (
    <div className={s.TableLetter}>
      <Box>
        <span className={s.TableLetter__letter}>{letter}</span>
      </Box>
    </div>
  );
}
