import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as HorizontalMenuIcon } from '../../../../assets/icons/horizontal-menu.svg';
import { IBaseCatalog } from '../../../../store/interfaces/ICatalog';
import { CatalogCardDropdown } from '../Dropdown/CatalogCardDropdown';
import s from './CatalogListItem.module.scss';

export interface CatalogListItemProps extends IBaseCatalog {
  isContextMenuAvailable: boolean;
}

export function CatalogListItem({ id, name, description, isActual, isContextMenuAvailable }: CatalogListItemProps) {
  return (
    <Link to={`/catalog/${id}`} className={s.CatalogListItem}>
      <div className={s.CatalogListItem__info}>
        <div className={s.CatalogListItem__title}>{name}</div>
        <div className={s.CatalogListItem__description}>{description}</div>
      </div>
      <div className={s.CatalogListItem__badges}>
        {isActual && <span className={s.CatalogListItem__badgeActual}>Актуальный</span>}
      </div>
      {isContextMenuAvailable && (
        <div className={s.CatalogListItem__menu}>
          <CatalogCardDropdown catalogId={id}>
            <HorizontalMenuIcon />
          </CatalogCardDropdown>
        </div>
      )}
    </Link>
  );
}
