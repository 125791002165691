import React from 'react';
import ReactSelect, { components } from 'react-select';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ReactComponent as CheckedCheckboxIcon } from '../../../../assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxIcon } from '../../../../assets/icons/checkbox.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { SelectOption } from './Select';
import s from './Select.module.scss';

export interface CheckboxSelectProps {
  name: string;
  options: SelectOption[];
  value?: SelectOption[];
  onChange?: (options: SelectOption[]) => void;
}

const LimitedChipsContainer = ({ children, hasValue, ...props }: ValueContainerProps<SelectOption, true>) => {
  if (!hasValue) {
    return (
      <components.ValueContainer hasValue={hasValue} {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  // тут возникают проблемы с типами
  // @ts-ignore
  const [chips, otherChildren] = children;

  const CHIPS_LIMIT = 2;

  const chipsArray = React.Children.toArray(chips);
  const displayChips = chipsArray.slice(0, CHIPS_LIMIT);
  const hiddenCounter = chipsArray.length - displayChips.length;

  return (
    <components.ValueContainer hasValue={hasValue} {...props}>
      {displayChips}

      {hiddenCounter > 0 && <div className={s.SelectCheckbox__hiddenCounter}>{hiddenCounter}</div>}
      {otherChildren}
    </components.ValueContainer>
  );
};

const RemoveIcon = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon className={s.SelectCheckbox__remove} />
    </components.MultiValueRemove>
  );
};

export function CheckboxSelect({ value, options, onChange, ...props }: CheckboxSelectProps) {
  return (
    <ReactSelect
      isMulti
      hideSelectedOptions={false}
      value={value}
      options={options}
      components={{ ValueContainer: LimitedChipsContainer, MultiValueRemove: RemoveIcon }}
      placeholder="Выберите элементы"
      onChange={(value) => {
        onChange?.(value as SelectOption[]);
      }}
      formatOptionLabel={(option, d) => {
        if (d.context === 'value') {
          return option.label;
        }

        const isOptionSelected = value?.some((selectedOption) => option.value === selectedOption.value);

        return (
          <div className={s.SelectCheckboxOption}>
            {isOptionSelected ? (
              <CheckedCheckboxIcon className={s.SelectCheckboxOption__icon} />
            ) : (
              <CheckboxIcon className={s.SelectCheckboxOption__icon} />
            )}
            <span>{option.label}</span>
          </div>
        );
      }}
      styles={{
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none'
        }),
        control: (provided, state) => ({
          ...provided,
          backgroundColor: '#ffffff',
          border: `1px solid #EAEAEB`,
          padding: '6px 0',
          boxShadow: 'none',
          width: '100%',
          borderRadius: 10,
          ...(state.isFocused
            ? {
                borderColor: '#065BFF'
              }
            : {})
        }),
        option: () => ({
          margin: '8px 8px',
          padding: '10px 16px',
          borderRadius: '8px',
          color: '#2B2C34',

          ':hover': {
            backgroundColor: 'rgba(43, 44, 52, 0.05);'
          }
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: 'rgba(6, 91, 255, 0.1)',
          borderRadius: 6,
          color: '#065BFF',
          fontSize: 14,
          lineHeight: '20px',
          fontWeight: 600,
          padding: '6px 8px',
          maxWidth: 150,
          pointerEvents: 'none'
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: '#065BFF',
          fontSize: 14,
          lineHeight: '20px',
          fontWeight: 600
        }),
        multiValueRemove: (base) => ({
          ...base,
          color: '#065BFF',
          pointerEvents: 'all',
          cursor: 'pointer',
          borderRadius: 4,

          ':hover': {
            backgroundColor: 'rgba(2, 36, 100, 0.1)'
          }
        })
      }}
      {...props}
    />
  );
}
