import { useEffect, useState } from 'react';
import { useFile } from '../../store/query/useFile';
import { navigateToLink } from '../../utils/navigation';

export function useFileLink(fileId: string | undefined) {
  const [link, setLink] = useState<string>();

  const fileRequest = useFile({ fileId: fileId || '' });

  useEffect(() => {
    setLink(undefined);
  }, [fileId]);

  if (!fileId) {
    return null;
  }

  return () => {
    if (!link) {
      fileRequest.run().then((response) => {
        if (response.token) {
          const newLink = document.location.origin + '/api/getFile?token=' + response.token;
          navigateToLink(newLink);
          setLink(newLink);
        }
      });
    } else {
      navigateToLink(link);
    }
  };
}
