import React, { useRef, useState } from 'react';
import { SidebarModal, SidebarModalProps } from '../../../common/components/ui/Modal/SidebarModal';
import { ModalHeader } from '../../../common/components/ui/Modal/ModalHeader';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { Button, ButtonColor, ButtonVariant } from '../../../common/components/ui/Button/Button';
import { useCatalogCreate } from '../../../store/mutation/useCatalogCreate';
import { FormErrorMessage } from '../../../common/components/ui/Form/FormErrorMessage';
import { CatalogDataForm, TCatalogFormData } from './CatalogDataForm';

export interface AddCatalogModalProps extends SidebarModalProps {
  onSubmit?: () => void;
}

export function AddCatalogModal({ onClose, onSubmit }: AddCatalogModalProps) {
  const [errorText, setErrorText] = useState(null);

  const formSubmitRef = useRef<HTMLInputElement>(null);

  const addCatalogRequest = useCatalogCreate();

  function handleSubmitForm(formData: TCatalogFormData) {
    addCatalogRequest
      .run({
        name: formData.title,
        date: formData.date.toISOString(),
        description: formData.short_description,
        isActual: Boolean(formData.is_actual)
      })
      .then(() => {
        onClose?.();
        onSubmit?.();
      })
      .catch((err) => {
        setErrorText(err.message);
      });
  }

  function handleSaveClick() {
    if (formSubmitRef.current) {
      formSubmitRef.current.click();
    }
  }

  return (
    <SidebarModal onClose={onClose}>
      <ModalHeader header="Добавление каталога">
        <Button leftIcon={<PlusIcon />} color={ButtonColor.secondary} onClick={handleSaveClick}>
          Создать
        </Button>
        <Button color={ButtonColor.primary} variant={ButtonVariant.secondary} onClick={() => onClose?.()}>
          Отменить
        </Button>
      </ModalHeader>
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
      <CatalogDataForm formSubmitRef={formSubmitRef} onSubmit={handleSubmitForm} />
    </SidebarModal>
  );
}
