import React from 'react';
import { IBaseCatalog } from '../../../../store/interfaces/ICatalog';
import { CatalogGridItem } from './CatalogGridItem';
import { CatalogListItem } from './CatalogListItem';

export type TView = 'grid' | 'list';

export interface CatalogProps {
  view: TView;
  data: IBaseCatalog;
  isContextMenuAvailable: boolean;
}

export function CatalogCard({ view, data, isContextMenuAvailable }: CatalogProps) {
  return view === 'grid' ? (
    <CatalogGridItem isContextMenuAvailable={isContextMenuAvailable} {...data} />
  ) : (
    <CatalogListItem isContextMenuAvailable={isContextMenuAvailable} {...data} />
  );
}
