import { formatNumber } from '@proscom/ui-utils';
import { IBudget } from '../store/interfaces/IBudget';

export function getBudgetInfo(budget: IBudget): [string, string] {
  if (budget.fact > 0) {
    return ['Факт ' + budget.year, formatNumber(budget.fact / 1e3, 1, true)];
  } else {
    return ['План ' + budget.year, formatNumber(budget.plan / 1e3, 1, true)];
  }
}

export function getCurrentBudgetInfo(budget: IBudget): [string, string] {
  if (budget.fact > 0) {
    return ['Факт ' + budget.year, formatNumber(budget.factCurrent / 1e3, 1, true)];
  } else {
    return ['План ' + budget.year, formatNumber(budget.planCurrent / 1e3, 1, true)];
  }
}

export function getDevelopmentBudgetInfo(budget: IBudget): [string, string] {
  if (budget.fact > 0) {
    return ['Факт ' + budget.year, formatNumber(budget.factDevelopment / 1e3, 1, true)];
  } else {
    return ['План ' + budget.year, formatNumber(budget.planDevelopment / 1e3, 1, true)];
  }
}

export function getCommitmentInfo(budget: IBudget) {
  return formatNumber(budget.commitment / 1e3, 1, true);
}
