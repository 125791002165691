import React from 'react';
import clsx from 'clsx';
import s from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  className?: string;
  labelProps?: React.HTMLProps<HTMLLabelElement>;
  onChange?: (state: boolean) => void;
}

export function Checkbox({ className, labelProps, children, onChange, disabled, ...props }: CheckboxProps) {
  return (
    <label
      className={clsx(s.Checkbox, className, {
        [s.Checkbox_disabled]: disabled
      })}
      {...labelProps}
    >
      <input
        type="checkbox"
        className={s.Checkbox__control}
        onChange={(e) => onChange?.(e.target.checked)}
        disabled={disabled}
        {...props}
      />
      <div className={s.Checkbox__label}>{children}</div>
    </label>
  );
}
