import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react/lib/es/useAsyncOperation';
import { IUser } from '../interfaces/IUser';

export interface IUserCreateVars
  extends Omit<
    IUser,
    'id' | 'accessibleCatalogs' | 'accessibleBlocks' | 'accessibleDirections' | 'accessibleProjects'
  > {
  accessibleCatalogIds: number[];
  accessibleBlockIds: number[];
  accessibleDirectionIds: number[];
  accessibleProjectIds: number[];
}

export function useUserCreate() {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (data: IUserCreateVars): Promise<IUser> => {
      return client
        .post('/api/user', data)
        .then((response) => response.data)
        .catch((err) => {
          console.error(err);
          throw new Error('Возникла ошибка при создании пользователя. Попробуйте еще раз');
        });
    },
    {
      singleton: true
    }
  );
}
