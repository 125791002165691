import React, { useCallback, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useContextStore } from '@proscom/prostore-react';
import clsx from 'clsx';
import { AuthStore } from '../../../store/AuthStore';
import { STORE_AUTH } from '../../../store/stores';
import { Box } from '../../../common/components/ui/Box/Box';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';
import { ReactComponent as LogOutIcon } from '../../../assets/icons/log-out.svg';
import logo from '../../../assets/logo.png';
import { useCatalogFile } from '../../../store/query/useFile';
import s from './TopBar.module.scss';

export interface ICatalogData {
  id: number;
  name: string;
}

export interface TopBarProps {
  isNavbarVisible: boolean;
  catalogData?: ICatalogData;
}

export function TopBar({ isNavbarVisible, catalogData }: TopBarProps) {
  const [isLoading, setIsLoading] = useState(false);
  const authStore = useContextStore<AuthStore>(STORE_AUTH);

  const location = useLocation();

  const catalogFileRequest = useCatalogFile({ catalogId: catalogData?.id || 0 });

  const handleDownloadPptxClick = useCallback(() => {
    setIsLoading(true);
    catalogFileRequest.run().then((response) => {
      if (response.token) {
        const newLink = document.location.origin + `/api/getFile?token=${response.token}&fileName=${catalogData?.name}`;
        window.location.href = newLink;
        setIsLoading(false);
      }
    });
  }, [catalogFileRequest]);

  return (
    <header className={s.Header}>
      <Box className={s.TopBar__box}>
        <Link to={'/'}>
          <img className={s.Header__logo} src={logo} alt={'Правительство Москвы'} />
        </Link>

        {isNavbarVisible && (
          <ul className={s.TopBar__menu}>
            <li className={s.TopBar__menuItem}>
              <NavLink
                exact
                to="/"
                isActive={(match, location) => !location.pathname.startsWith('/users')}
                activeClassName={s.TopBar__menuLink_active}
                className={s.TopBar__menuLink}
              >
                Каталоги
              </NavLink>
            </li>

            <li>
              <NavLink to="/users" activeClassName={s.TopBar__menuLink_active} className={s.TopBar__menuLink}>
                Доступы
              </NavLink>
            </li>
          </ul>
        )}

        <div className={s.Header__actions}>
          {catalogData ? (
            <button
              className={clsx(s.Header__actionButton, {
                [s.Header__actionButton_primary]: !isLoading
              })}
              disabled={isLoading}
              onClick={handleDownloadPptxClick}
            >
              <DocumentIcon />
              <span>{isLoading ? 'Подготовка...' : 'Загрузить PDF'}</span>
            </button>
          ) : null}

          <button className={s.Header__actionButton} onClick={() => authStore.logOut()}>
            <LogOutIcon />
            <span>Выйти</span>
          </button>
        </div>
      </Box>
    </header>
  );
}
