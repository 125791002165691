import React from 'react';
import { useStore } from '@proscom/prostore-react';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';
import { Search } from '../../../common/components/ui/Input/Search';
import { useQuery } from '../../../common/hooks/useQuery';
import { Button, ButtonColor, ButtonVariant } from '../../../common/components/ui/Button/Button';
import { Box } from '../../../common/components/ui/Box/Box';
import { CatalogSortDropdown } from '../../../common/components/ui/Dropdown/CatalogSortDropdown';
import { CatalogViewStore } from '../../../store/CatalogViewStore';
import { STORE_VIEW } from '../../../store/stores';
import s from './CatalogsFilter.module.scss';

export interface CatalogsFilterProps {
  addButtonVisible: boolean;
}

export function CatalogsFilter({ addButtonVisible }: CatalogsFilterProps) {
  const [_, viewStore] = useStore<CatalogViewStore>(STORE_VIEW);

  const [query, changeQuery] = useQuery();

  const handleQueryChange = (value: string) => {
    changeQuery({
      q: value
    });
  };

  const handleViewClick = () => {
    viewStore.toggleView();
  };

  const handleAddCatalogClick = () => {
    changeQuery({
      action: 'catalog-add'
    });
  };

  return (
    <Box>
      <div className={s.CatalogsFilter}>
        <form action="" method="get" className={s.CatalogsFilter__search}>
          <Search value={query?.q} onChange={handleQueryChange} placeholder="Поиск по каталогу" />
        </form>
        <div className={s.CatalogsFilter__sortButton}>
          <CatalogSortDropdown />
        </div>
        <div className={s.CatalogsFilter__viewButton}>
          <Button variant={ButtonVariant.tertiary} onClick={handleViewClick} onlyIcon>
            {viewStore.getValue() === 'list' ? <GridIcon /> : <ListIcon />}
          </Button>
        </div>
        {addButtonVisible && (
          <div className={s.CatalogsFilter__addButton}>
            <Button color={ButtonColor.primary} onClick={handleAddCatalogClick} leftIcon={<PlusIcon />}>
              Создать каталог
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
}
