import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { useClickOutside } from '@proscom/ui-react';
import { Button, ButtonColor, ButtonVariant } from '../Button/Button';
import { useQuery } from '../../../hooks/useQuery';
import { Dropdown } from './Dropdown';

export interface CatalogCardDropdownProps extends React.HTMLProps<HTMLButtonElement> {
  catalogId: number;
}

export function CatalogCardDropdown({ catalogId, children, ref, ...props }: CatalogCardDropdownProps) {
  const [query, changeQuery] = useQuery();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [refElement, setRefElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(refElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [4, 4]
        }
      }
    ]
  });

  const options = [
    {
      label: 'Редактировать',
      onClick: () => {
        changeQuery({
          catalog: catalogId,
          action: 'catalog-edit'
        });
      }
    },
    {
      label: 'Удалить каталог',
      onClick: () => {
        changeQuery({
          catalog: catalogId,
          action: 'catalog-delete'
        });
      }
    }
  ];

  useClickOutside({ current: popperElement }, () => {
    setIsDropdownVisible(false);
  });

  return (
    <>
      <Button
        variant={ButtonVariant.quaternary}
        color={ButtonColor.tertiary}
        ref={setRefElement}
        onClick={(e) => {
          e.preventDefault();
          setIsDropdownVisible(!isDropdownVisible);
        }}
      >
        {children}
      </Button>

      {isDropdownVisible && (
        <Dropdown
          options={options}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          onClose={() => setIsDropdownVisible(false)}
        />
      )}
    </>
  );
}
