import React, { useMemo } from 'react';
import { useStore } from '@proscom/prostore-react';
import { useQuery } from '../../common/hooks/useQuery';
import { Spoiler } from '../../common/components/ui/Spoiler/Spoiler';
import { Header } from '../../common/components/ui/Header/Header';
import { Box } from '../../common/components/ui/Box/Box';
import { useCatalogs } from '../../store/query/useCatalogs';
import { CatalogCard } from '../../common/components/ui/Card/CatalogCard';
import { queryLoader } from '../../common/components/utils/queryLoader';
import { IBaseCatalog } from '../../store/interfaces/ICatalog';
import { getValidSortValue } from '../../utils/catalog';
import { STORE_AUTH, STORE_VIEW } from '../../store/stores';
import { CatalogViewStore } from '../../store/CatalogViewStore';
import { NothingFound } from '../../common/components/ui/NothingFound/NothingFound';
import { AuthStore, Role } from '../../store/AuthStore';
import { CatalogsFilter } from './filter/CatalogsFilter';
import { CatalogList } from './list/CatalogList';
import { CatalogModal } from './modal/CatalogModal';
import { AddCatalogModal } from './modal/AddCatalogModal';
import { DeleteCatalogModal } from './modal/DeleteCatalogModal';

export type TCatalogSort = 'actual' | 'non-actual' | undefined;

export interface ICatalogGroupedByYear {
  [year: string]: IBaseCatalog[];
}

export default function CatalogsPage() {
  const [query, changeQuery] = useQuery();
  const querySearch = query?.q;
  // const queryView = query?.view || 'list';
  const queryCatalog = query?.catalog;
  const queryAction = query?.action;
  const querySort: TCatalogSort = getValidSortValue<TCatalogSort>(query?.sort, ['actual', 'non-actual']);

  const [viewState, viewStore] = useStore<CatalogViewStore>(STORE_VIEW);
  const [authState, authStore] = useStore<AuthStore>(STORE_AUTH);

  const isAdmin = authState.authData?.user.role === Role.Admin;

  const catalogsQuery = useCatalogs();
  const catalogsData = catalogsQuery.state.data;

  const currentCatalog = useMemo(() => {
    return catalogsData?.find((catalog) => catalog.id === +queryCatalog);
  }, [catalogsData, queryCatalog]);

  const [groupedByYearCatalogs, sortedYears] = useMemo(() => {
    const groups: ICatalogGroupedByYear = {};
    const years: number[] = [];

    catalogsData?.forEach((catalog) => {
      const catalogDateYear = new Date(catalog.date).getFullYear();

      if (querySort === 'actual' && !catalog.isActual) {
        return;
      }

      if (querySort === 'non-actual' && catalog.isActual) {
        return;
      }

      if (querySearch) {
        if (
          !(
            catalog.name.toLowerCase().indexOf(querySearch) > -1 ||
            catalog.description.toLowerCase().indexOf(querySearch) > -1
          )
        ) {
          return;
        }
      }

      if (!groups[catalogDateYear]) {
        groups[catalogDateYear] = [];
        years.push(catalogDateYear);
      }

      groups[catalogDateYear].push(catalog);
    });

    years.forEach((year) => {
      groups[year].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    });

    years.sort((a, b) => b - a);

    return [groups, years];
  }, [catalogsData, querySearch, querySort]);

  function handleSubmitModalForm() {
    catalogsQuery.load();
  }

  return (
    <>
      <Header header="Каталоги проектов" />

      <CatalogsFilter addButtonVisible={isAdmin} />

      {queryLoader(catalogsQuery) || (
        <Box>
          {!sortedYears.length && <NothingFound label="Ничего не найдено" />}
          {sortedYears.map((year, yearIndex) => (
            <Spoiler header={year.toString()}>
              <CatalogList view={viewStore.getValue()}>
                {groupedByYearCatalogs[year].map((catalog) => (
                  <CatalogCard
                    key={catalog.id}
                    isContextMenuAvailable={authState.authData?.user.role === Role.Admin}
                    view={viewStore.getValue()}
                    data={catalog}
                  />
                ))}
              </CatalogList>
            </Spoiler>
          ))}
        </Box>
      )}

      {queryAction === 'catalog-edit' && currentCatalog && isAdmin && (
        <CatalogModal
          catalogId={currentCatalog.id}
          data={currentCatalog}
          onClose={() => {
            changeQuery({
              catalog: undefined
            });
          }}
          onSubmit={handleSubmitModalForm}
        />
      )}

      {queryAction === 'catalog-add' && isAdmin && (
        <AddCatalogModal
          onClose={() => {
            changeQuery({
              action: undefined
            });
          }}
          onSubmit={handleSubmitModalForm}
        />
      )}

      {queryAction === 'catalog-delete' && currentCatalog && isAdmin && (
        <DeleteCatalogModal
          catalogId={+queryCatalog}
          onClose={() => {
            changeQuery({
              action: undefined
            });
          }}
          onApprove={handleSubmitModalForm}
        />
      )}
    </>
  );
}
