export function compressName(fullName: string) {
  const nameArray = fullName.split(' ');

  if (nameArray.length === 1) {
    return nameArray[0];
  }

  return (
    nameArray[0] +
    ' ' +
    nameArray
      .slice(1)
      .map((namePart) => namePart[0].toUpperCase() + '.')
      .join(' ')
  );
}
