import React from 'react';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/document.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import { IFile } from '../../../../store/interfaces/IFile';
import { useFileLink } from '../../../hooks/useFileLink';
import s from './DocumentCard.module.scss';

export interface DocumentCardProps {
  file: IFile;
}

export function DocumentCard({ file }: DocumentCardProps) {
  const handleLinkClick = useFileLink(file.id);

  return (
    <button className={s.DocumentCard} onClick={() => handleLinkClick?.()}>
      <div className={s.DocumentCard__documentIcon}>
        <DocumentIcon />
      </div>
      <div className={s.DocumentCard__information}>
        <div className={s.DocumentCard__fileName}>{file.name}</div>
      </div>
      <div className={s.DocumentCard__downloadIcon}>
        <DownloadIcon />
      </div>
    </button>
  );
}
