import React, { useMemo } from 'react';
import { UserPic } from '../UserPic/UserPic';
import { useQuery } from '../../../hooks/useQuery';
import { IDeputy } from '../../../../store/interfaces/IDeputy';
import { IBudget } from '../../../../store/interfaces/IBudget';
import { getBudgetYears } from '../../../../utils/catalog';
import { getBlockColor } from '../../../../utils/blockColors';
import { getBlockImageUrl } from '../../../../utils/block';
import { getBudgetInfo } from '../../../../utils/budget';
import s from './DeputyCard.module.scss';

export interface IYearBudget {
  year: number;
  status: 'plan' | 'actual';
  budget: number;
}

export interface DeputyCardProps {
  data: IDeputy;
  isBudgetVisible: boolean;
}

export function DeputyTable({ budgetData }: { budgetData: IBudget[] }) {
  const sortedBudgetsData = getBudgetYears(budgetData);
  const currentYear = new Date().getFullYear();

  return (
    <table className={s.DeputyCardBudget}>
      <thead>
        <tr>
          {sortedBudgetsData.map((budget) => {
            const [budgetLabel] = getBudgetInfo(budget);

            return (
              <th className={s.DeputyCardBudget__header} key={budget.year}>
                {budgetLabel}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={s.DeputyCardBudget__tip} colSpan={budgetData.length}>
            Общий бюджет (млн)
          </td>
        </tr>
        <tr>
          {sortedBudgetsData.map((budget) => {
            const [budgetLabel, budgetValue] = getBudgetInfo(budget);

            return (
              <td className={s.DeputyCardBudget__value} key={budget.year}>
                {budgetValue}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}

export function DeputyStatItem({ name, value }: { name: string; value: number }) {
  return (
    <div className={s.DeputyCardStat}>
      <div className={s.DeputyCardStat__label}>{name}</div>
      <div className={s.DeputyCardStat__value}>{value}</div>
    </div>
  );
}

export function DeputyCard({ data, isBudgetVisible }: DeputyCardProps) {
  const [query, changeQuery] = useQuery();

  const imageUrl = useMemo(() => {
    return getBlockImageUrl(data);
  }, [data]);

  return (
    <button onClick={() => changeQuery({ deputy: data.id })} className={s.DeputyCard}>
      <div className={s.DeputyCard__header}>
        <div className={s.DeputyCard__headerUserPic}>
          <UserPic picture={imageUrl} radius={20} fontSize={14} name={data.name} color={getBlockColor(data.id)} />
        </div>
        <div className={s.DeputyCard__headerUserName}>{data.name}</div>
      </div>
      {isBudgetVisible ? (
        <DeputyTable budgetData={data.budgets} />
      ) : (
        <div className={s.DeputyCard__content}>
          <DeputyStatItem name="Направлений" value={data.directionsCount} />
          <DeputyStatItem name="Проектов" value={data.projectsCount} />
        </div>
      )}
    </button>
  );
}
