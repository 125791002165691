import React, { useMemo } from 'react';
import { IUseAxiosQueryOptions, useAxiosQuery } from '@proscom/prostore-axios-react/lib/es/useAxiosQuery';
import { IProject } from '../interfaces/IProject';

export interface ProjectQueryVars {}

export function useProject(projectId: number, variables: ProjectQueryVars = {}) {
  const queryOptions: IUseAxiosQueryOptions<ProjectQueryVars | null, IProject> = useMemo(
    () => ({
      query: {
        url: `/api/project/${projectId}`,
        method: 'get'
      }
    }),
    [projectId]
  );

  return useAxiosQuery({
    queryOptions,
    variables
  });
}
