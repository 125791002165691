import React from 'react';
import DatePicker from 'react-datepicker';
import clsx from 'clsx';
import ru from 'date-fns/locale/ru';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { TextInputProps } from './TextInput';
import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.scss';
import s from './TextInput.module.scss';

export interface DateInputProps extends Omit<TextInputProps, 'value' | 'onChange' | 'defaultValue'> {
  value?: Date;
  defaultValue?: Date;
  onChange?: (date: Date | string) => void;
}

export function DateInput({ value, onChange, isClearable = true, classes, className }: DateInputProps) {
  return (
    <div className={clsx(s.TextInput, className)}>
      <DatePicker
        locale={ru}
        onChange={(date) => onChange?.(date as Date)}
        selected={value}
        dateFormat="dd.MM.yyyy"
        className={clsx(s.TextInput__input, s.TextInput__input_withLeftIcon, {
          [s.TextInput__input_withRightIcon]: value
        })}
        placeholderText="Выберите дату"
      />
      <div className={clsx(s.TextInput__icon, s.TextInput__leftIcon, classes?.leftIcon)}>
        <CalendarIcon />
      </div>
      {isClearable && value && (
        <button className={clsx(s.TextInput__icon, s.TextInput__resetButton)} onClick={() => onChange?.('')}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
}
