import { Routes, RouteType } from '../common/components/utils/Routes';
import { withDefaultLayout } from './_layouts/DefaultLayout';
import { createWithUserLayout } from './_layouts/UserLayout';

import CatalogsPage from './index/CatalogsPage';
import UsersPage from './users/UsersPage';
import { CatalogBasePage } from './catalog/CatalogBasePage';
import ProjectPage from './project/ProjectPage';
import { PresentationPage } from './project/PresentationPage';
import SignInPage from './signIn/SignInPage';
import NotFoundPage from './notFound/NotFoundPage';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: withDefaultLayout(createWithUserLayout()(CatalogsPage))
  },
  {
    path: '/users',
    exact: true,
    component: withDefaultLayout(createWithUserLayout({ admin: true })(UsersPage))
  },
  {
    path: '/catalog/:catalogId/project/:projectId',
    exact: true,
    component: withDefaultLayout(createWithUserLayout()(ProjectPage))
  },
  {
    path: '/catalog/:catalogId/project/:projectId/presentation',
    exact: true,
    component: withDefaultLayout(createWithUserLayout({ fullScreen: true })(PresentationPage))
  },
  {
    path: '/catalog/:catalogId',
    component: withDefaultLayout(createWithUserLayout()(CatalogBasePage))
  },
  {
    path: '/login',
    exact: true,
    component: withDefaultLayout(SignInPage)
  },
  {
    component: NotFoundPage
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
