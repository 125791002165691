import React from 'react';
import { Link } from 'react-router-dom';
import { createUrlPreserver } from '@proscom/prostore-react-router';
import { ReactComponent as ChevronRight } from '../../../../assets/icons/chevron-right.svg';
import { BudgetCompactTable } from '../Table/BudgetCompactTable';
import { Tag } from '../Tag/Tag';
import { IProject } from '../../../../store/interfaces/IProject';
import { useQuery } from '../../../hooks/useQuery';
import s from './ProjectBudgetCard.module.scss';

export interface ProjectBudgetCardProps {
  data: IProject;
  showBudget: boolean;
}

export function ProjectBudgetCard({ data, showBudget }: ProjectBudgetCardProps) {
  const [query, changeQuery] = useQuery();
  const urlPreserver = createUrlPreserver(query || {});

  return (
    <Link className={s.ProjectBudgetCard} to={urlPreserver(`/catalog/${data.catalog.id}/project/${data.id}`)}>
      <div className={s.ProjectBudgetCard__header}>
        <div className={s.ProjectBudgetCard__title}>{data.name}</div>
        <div className={s.ProjectBudgetCard__chevron}>
          <div className={s.ProjectBudgetCard__chevronIcon}>
            <ChevronRight />
          </div>
        </div>
      </div>
      <div className={s.ProjectBudgetCard__content}>
        <div className={s.ProjectBudgetCard__isNew}>
          {data.state === 'Новый проект' && <Tag autoColored>Новый</Tag>}
        </div>
        <div className={s.ProjectBudgetCard__budget}>
          {showBudget && <BudgetCompactTable data={data.budgets} collapsed />}
        </div>
      </div>
    </Link>
  );
}
