import React, { useEffect, useMemo } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { Select, SelectOption, SelectProps } from '../Input/Select';

export interface FormSelectProps extends SelectProps {
  name: string;
  registerOptions?: RegisterOptions;
  defaultValue?: SelectOption;
}

export function FormSelect({ name, onChange, registerOptions, defaultValue, ...props }: FormSelectProps) {
  const { register, unregister, watch, control } = useFormContext<{
    [name: string]: string;
  }>();
  const fieldData = watch(name) || defaultValue?.value;

  useEffect(() => {
    register({ name, ...registerOptions });
    return () => unregister(name);
  }, [name, register, registerOptions, unregister]);

  const currentValue = useMemo(() => {
    return props.options.find((option) => option.value === fieldData);
  }, [fieldData, props.options]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={currentValue}
      render={({ onChange: _onChange }) => {
        return (
          <Select
            name={name}
            value={currentValue}
            onChange={(e) => {
              _onChange(e);
              onChange?.(e);
            }}
            {...props}
          />
        );
      }}
    />
  );
}
