import React from 'react';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import { UserPic } from '../../../common/components/ui/UserPic/UserPic';
import { useQuery } from '../../../common/hooks/useQuery';
import { Box } from '../../../common/components/ui/Box/Box';
import { IUser } from '../../../store/interfaces/IUser';
import s from './UserRow.module.scss';

export type TRole = 'ADMIN' | 'USER';

export interface UserRowProps {
  data: IUser;
}

export function UserRow({ data }: UserRowProps) {
  const [query, changeQuery] = useQuery();

  const { name, role, email } = data;

  return (
    <button className={s.UserRow} onClick={() => changeQuery({ user: data.id })}>
      <Box className={s.UserRow__box}>
        <div className={s.UserRow__person}>
          <UserPic radius={20} name={name} fontSize={14} color="#06B4FF" className={s.UserRow__picture} />
          <div className={s.UserRow__name}>{data.name}</div>
        </div>
        <div className={s.UserRow__role}>{data.role === 'ADMIN' ? 'Администратор' : 'Пользователь'}</div>
        <div className={s.UserRow__email}>
          <a href={`mailto:${data.email}`} className={s.UserRow__emailLink}>
            {data.email}
          </a>
        </div>
        <div className={s.UserRow__actions}>
          <div className={s.UserRow__actionButton}>
            <ChevronRightIcon />
          </div>
        </div>
      </Box>
    </button>
  );
}
